import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { updateBook } from "../redux/action/action";

const CssTextField = styled(TextField)({
  "& .MuiInputBase-root": {
    borderRadius: "12px",
    width: "100%",
    border: "0.2px solid #503981",
  },
  "&:hover .MuiInputBase-root": {
    border: "0.9px solid #503981",
  },
});
export default function Editbooks({
  open,
  setModalOpen,
  heading,
  bookId,
  onBookUpdate,
}) {
  const [formData, setFormData] = React.useState({
    title: "",
    subtitle: "",
    author: "",
  });
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const book = useSelector((state) =>
    state.reducer.data.books.find((b) => b._id === bookId)
  );
  useEffect(() => {
    if (book) {
      setFormData({
        title: book.title,
        subtitle: book.subtitle,
        author: book.author,
      });
    }
  }, [book]);

  const handelSubmit = () => {
    setLoading(true);
    dispatch(updateBook(bookId, formData));
    setTimeout(() => {
      handleClose();
      setFormData({
        title: "",
        subtitle: "",
        author: "",
      });
      setLoading(false);
      onBookUpdate();
    }, 2000);
  };
  const handleClose = () => {
    setModalOpen(false);
  };

  const ColorButton = styled(Button)(() => ({
    fontSize: "1vw",
    fontFamily: "Poppins",
    backgroundImage: "linear-gradient(99deg, #6A1AC3 28.42%, #1D0A32 126.77%)",
    color: "white",
    "&:hover": {
      backgroundImage:
        "linear-gradient(99deg, #6A1AC3 28.42%, #1D0A32 126.77%)",
    },
  }));

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        classes={{ paper: "custom-dialog-paper-edit" }}
      >
        <DialogContent sx={{ width: "50%", padding: "55px" }}>
          <h4 style={{ textAlign: "center", fontFamily: "Poppins" }}>
            {heading}
          </h4>
          <Box
            component='form'
            sx={{
              display: "flex",
              flexDirection: "column",
              m: "auto",
              width: "80%",
            }}
          >
            <CssTextField
              placeholder='Enter title'
              margin='normal'
              value={formData.title}
              onChange={handleChange}
              name='title'
              inputProps={{
                style: { textAlign: "center", fontFamily: "Poppins" },
              }}
            />
            <CssTextField
              placeholder='Enter sub-title'
              margin='normal'
              value={formData.subtitle}
              onChange={handleChange}
              name='subtitle'
              inputProps={{
                style: { textAlign: "center", fontFamily: "Poppins" },
              }}
            />
            <CssTextField
              placeholder='Enter author'
              margin='normal'
              value={formData.author}
              onChange={handleChange}
              name='author'
              inputProps={{
                style: { textAlign: "center", fontFamily: "Poppins" },
              }}
            />
            <Box
              sx={{ width: "100%", display: "flex", justifyContent: "center" }}
            >
              <ColorButton
                variant='contained'
                color='primary'
                sx={{
                  mt: 2,
                  width: "50%",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  borderRadius: "15px",
                }}
                // type="submit"
                disabled={loading}
                onClick={handelSubmit}
              >
                Update
              </ColorButton>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            style={{
              position: "absolute",
              right: 4,
              top: 4,
              padding: "0px",
              backgroundColor: "#503981",
              borderRadius: "20px",
              minWidth: "20px",
            }}
          >
            <CloseIcon style={{ color: "white" }} />
          </Button>
        </DialogActions>
      </Dialog>
      <style>
        {`
          .custom-dialog-paper-edit {
            border-radius: 20px;
            max-width:500px;
            width:1000px;
            flex-direction:row;
          }
        `}
      </style>
    </React.Fragment>
  );
}
