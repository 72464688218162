import {
  ADD_SUBCHAPTER_REQUEST,
  ADD_SUBCHAPTER_FAILURE,
  ADD_SUBCHAPTER_SUCCESS,
} from "../actiontype/actiontype";

let initialState = {
  loading: false,
  error: "",
  singleBook: {},
};

const addsubchapterreducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_SUBCHAPTER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case ADD_SUBCHAPTER_FAILURE:
      return {
        ...state,
        loading: false,
        singleBook: {},
        erro: action.payload,
      };

    case ADD_SUBCHAPTER_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    // case "CLEAR_STATE":
    //   return {
    //     ...state,
    //     loading: false,
    //     error: "",
    //   };
    default:
      return { ...state };
  }
};

export default addsubchapterreducer;
