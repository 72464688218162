import React, { useState,useId } from "react";
import DropdownButton from "react-bootstrap/DropdownButton";
import "../../App.css";
import "./MobilePreview.css";
import "react-device-frameset/styles/marvel-devices.min.css";
import Dropdown from "react-bootstrap/Dropdown";
import { useDispatch,useSelector } from "react-redux";
import AmzKindle from "./kindleDevice/AmzKindle";
import Ipad from "./IPad/Ipad";
import Iphone from "./Iphone14/Iphone";
import IpadPro from "./IPadPro/IpadPro";
import PrintPreview from "./PrintPreview"; // Import PrintPreview component here
import { setSelectedPageSize } from "../../redux/action/action";

function MobilePreview({ text, title }) {
  const dispatch = useDispatch();
  const [printPreviewKey, setPrintPreviewKey] = useState(0);
  const selectedPageSize = useSelector((state) => state.pageSize.selectedPageSize);
  const selectedTemplate = useSelector((state) => state.template.selectedTemplate);

  let arr = [
    "A4",
    "8.5 x 11",
    "8 x 10",
    "6 x 9",
    "11 x 8.5",
    "10 x 8",
    "9 x 6",
  ];
  const [devices, selectDevices] = useState("8.5 x 11");

  function handelClick(name) {
    selectDevices(name);
    dispatch(setSelectedPageSize(name));
    setPrintPreviewKey((prevKey) => prevKey + 1);
  }

  const idx = useId();

  const getFontStyles = () => {
    switch (selectedTemplate) {
      case "Template 1":
        return { fontFamily: "Poppins", fontSize: "15.5px" };
      case "Template 2":
        return { fontFamily: "Georgia, serif", fontSize: "18px" };
      case "Template 3":
        return { fontFamily: "Courier New, monospace", fontSize: "14px" };
      case "Template 4":
        return { fontFamily: "Times New Roman, serif", fontSize: "20px" };
      default:
        return {};
    }
  };

  return (
    <>
      <DropdownButton id='dropdown-item-button' title={devices ? devices : "Devices"} style={{ background: "black", border: "none", borderRadius: "6px" }} variant='dark'>
        {arr.map((name, id) => (
          <>
            {name === "A4" && (
              <Dropdown.Item key='Print-divider' as='button' disabled style={{ color: "gray", cursor: "default", fontSize: "13px", paddingLeft: "8px" }}>
                Print
              </Dropdown.Item>
            )}
            {name === "8.5 x 11" && (
              <Dropdown.Item key='Portrait-divider' as='button' disabled style={{ color: "gray", cursor: "default", fontSize: "13px", paddingLeft: "8px" }}>
                Portrait
              </Dropdown.Item>
            )}
            {name === "11 x 8.5" && (
              <Dropdown.Item key='Landscape-divider' as='button' disabled style={{ color: "gray", cursor: "default", fontSize: "13px", paddingLeft: "8px" }}>
                Landscape
              </Dropdown.Item>
            )}
            {name === "Paper White" && (
              <Dropdown.Item key='kindle-divider' as='button' disabled style={{ color: "gray", cursor: "default", fontSize: "13px", paddingLeft: "8px" }}>
                Kindle
              </Dropdown.Item>
            )}
            {name === "iPhone 14 Pro" && (
              <Dropdown.Item key='apple-divider' as='button' disabled style={{ color: "gray", cursor: "default", fontSize: "13px", paddingLeft: "8px" }}>
                Apple
              </Dropdown.Item>
            )}
            <Dropdown.Item as='button' key={idx} onClick={() => handelClick(name)} variant='dark' style={{ marginTop: "0px" }}>
              {name}
            </Dropdown.Item>
          </>
        ))}
      </DropdownButton>
      {devices === "iPhone 14 Pro" ? (
        <Iphone font={getFontStyles} data={text} />
      ) : devices === "iPad" ? (
        <Ipad font={getFontStyles} data={text} />
      ) : devices === "Paper White" ? (
        <AmzKindle font={getFontStyles} data={text} />
      ) : devices === "iPad Pro" ? (
        <IpadPro font={getFontStyles} data={text} />
      ) : (
        <PrintPreview text={text} key={printPreviewKey} selectedPageSize={selectedPageSize}showButton={true} showRadio={false} />
      )}
    </>
  );
}

export default MobilePreview;



