import {
    GOOGLE_SIGNIN_REQUEST,
    GOOGLE_SIGNIN_SUCCESS,
    GOOGLE_SIGNIN_FAILURE,
  } from '../actiontype/actiontype';
  
  const initialState = {
    user: null,
    error: null,
    loading: false,
  };
  
  const signinReducer = (state = initialState, action) => {
    switch (action.type) {
      case GOOGLE_SIGNIN_REQUEST:
        return {
          ...state,
          loading: true,
        };
  
      case GOOGLE_SIGNIN_SUCCESS:
        return {
          ...state,
          user: action.payload,
          error: null,
          loading: false,
        };
  
      case GOOGLE_SIGNIN_FAILURE:
        return {
          ...state,
          user: null,
          error: action.payload,
          loading: false,
        };
  
      default:
        return state;
    }
  };
  
  export default signinReducer;
  