import {
    FETCH_PDF_REQUEST,
    FETCH_PDF_SUCCESS,
    FETCH_PDF_FAILURE,
  } from '../actiontype/actiontype';
  
  const initialState = {
    pdfData: null,
    loading: false,
    error: null,
  };
  
  const pdfReducer = (state = initialState, action) => {
    switch (action.type) { 
      case FETCH_PDF_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case FETCH_PDF_SUCCESS:
        return {
          ...state,
          pdfData: action.payload,
          loading: false,
        };
      case FETCH_PDF_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default pdfReducer;
  