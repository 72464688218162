const initialState = {
    coverImage: "",
  };
  
  const bookcoverReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_COVER_IMAGE':
        return {
          ...state,
          coverImage: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default bookcoverReducer;
  