import React from "react";
import "./kindle.css";
import parse from "html-react-parser";
export default function AmzKindle({ data, font }) {
  function removeEmptyPTags(content) {
    const doc = new DOMParser().parseFromString(content, "text/html");
    const pTags = doc.querySelectorAll("p");

    pTags.forEach((pTag) => {
      if (!pTag.textContent.trim()) {
        pTag.remove();
      } else {
        pTag.style.margin = "0";
      }
    });

    return doc.body.innerHTML;
  }

  const cleanedText = removeEmptyPTags(data);
  return (
    <div className='container d-flex justify-content-center align-items-center py-5'>
      <div className='kindle'>
        <div
          className='screen'
          style={{
            padding: "20px",
            whiteSpace: "pre-line",
            wordWrap: "break-word",
            ...font(),
          }}
        >
          {parse(data)}
          <div className='overlay w-100 h-100 d-none' />
          <div className='controls w-100 h-100 d-none' />
        </div>
      </div>
    </div>
  );
}
