import React,{useState} from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Sidebar from "./components/Sidebar/Sidebar";
import { Routes, Route, useNavigate } from "react-router-dom";
import Book from "./Pages/Book";
import EditorSidebar from "./components/EditorSidebar";
import Signin from "./Pages/Signin";
import Signup from "./Pages/Signup";
import Forgot from "./Pages/Forgot";
import Reset from "./Pages/Reset";
import EmailVerification from "./Pages/EmailVerification";
import Privateroutes from "./components/Privateroutes";
import AdminPanel from "./components/AdminPanel";
import SuperAdminPanel from "./components/SuperAdminPanel";
import EpubViewer from "./components/EpubViewer";
import Contents from "./components/Contents";
import Navbar from "./components/Navbar/Navbar";
import TitlePage from "./components/TitlePage";
import Copyright from "./components/Copyright";
import Orders from "./components/Orders/Orders";
import Checkout from "./components/Orders/Checkout";
import OrderList from "./components/Orders/OrderList";
import Settings from "./components/Settings/Settings";
import UserTable from "./components/UserTable";
import AdminTable from "./components/AdminTable";
import BookEditor from "./components/BookCover/BookEditor";
import Paymentsuccess from "./components/Orders/Paymentsuccess";
function PrivateRouteWithNavbar({ Component, ...rest }) {
  return <Privateroutes Component={Component} {...rest} />;
}

function App() {
  const [isActive, setIsActive] = useState(true);
  const navigate = useNavigate();


  // Check if the current route is '/' or '/signup'
  const isSignInOrSignUp = ["/", "/signup", "/signin"].includes(
    window.location.pathname
  );
  
  return (
    <div className='App'>
      {!isSignInOrSignUp && <Navbar />}
      <Routes>
        <Route path='/' index element={<Signin />} />
        <Route
          path='/books'
          element={
            <div className='wrapper'>
              <div className={`sidebar ${isActive ? "active" : ""}`}>
                <Sidebar isActive={isActive} setIsActive={setIsActive} />
              </div>
              <div className='contents'>
                <PrivateRouteWithNavbar Component={Book} />
              </div>
            </div>
          }
        />


        <Route
          path='/editor/:id'
          element={
            <>
              <PrivateRouteWithNavbar Component={EditorSidebar} />
            </>
          }
        />

        <Route
          path='/epub/:id'
          element={
            <>
              <PrivateRouteWithNavbar Component={EpubViewer} />
            </>
          }
        />

        <Route
          path='/editor/:id/contents'
          element={
            <>
              <PrivateRouteWithNavbar Component={Contents} />
            </>
          }
        />

        <Route
          path='/editor/:id/title-page'
          element={
            <>
              <PrivateRouteWithNavbar Component={TitlePage} />
            </>
          }
        />
        <Route
          path='/editor/:id/copyright'
          element={
            <>
              <PrivateRouteWithNavbar Component={Copyright} />
            </>
          }
        />

        <Route
          path='/orders/:id'
          element={
            <>
              <PrivateRouteWithNavbar Component={Orders} />
            </>
          }
        />
        <Route
          path='/bookcovereditor/:id'
          element={
            <>
              <PrivateRouteWithNavbar Component={BookEditor} />
            </>
          }
        />

        <Route
          path='/checkout/:id'
          element={
            <>
              <PrivateRouteWithNavbar Component={Checkout} />
            </>
          }
        />
        <Route
          path='/paymentsuccessful'
          element={
            <>
              <PrivateRouteWithNavbar Component={Paymentsuccess} />
            </>
          }
        />
        <Route
          path='/orderlist'
          element={
            <>
              <PrivateRouteWithNavbar Component={OrderList} />
            </>
          }
        />
        <Route
          path='/usertable'
          element={
            <>
              <PrivateRouteWithNavbar Component={UserTable} />
            </>
          }
        />
        <Route
          path='/admintable'
          element={
            <>
              <PrivateRouteWithNavbar Component={AdminTable} />
            </>
          }
        />
        <Route
          path='/settings'
          element={
            <>
              <PrivateRouteWithNavbar Component={Settings} />
            </>
          }
        />
        <Route path='/signup' element={<Signup />} />
        <Route
          path='/admin'
          element={
            <>
              <PrivateRouteWithNavbar Component={AdminPanel} />
            </>
          }
        />
        <Route
          path='/superadmin'
          element={
            <>
              <PrivateRouteWithNavbar Component={SuperAdminPanel} />
            </>
          }
        />
        <Route path='/signin' element={<Signin />} />
        {/* <Route path='/forgot' element={<Forgot />} /> */}
        {/* <Route path='/reset-password/:token' element={<Reset />} /> */}
        {/* <Route path='/verify-email/:token' element={<EmailVerification />} /> */}
      </Routes>
    </div>
  );
}

export default App;
