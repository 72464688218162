import React, { useState, useEffect } from "react";
import Signinimg from "../images/bookssign.png";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LockIcon from "@mui/icons-material/Lock";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { signUp, clearState } from "../redux/action/action";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#513981",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#513981",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#513981",
      borderRadius: "10px",
    },
    "&:hover fieldset": {
      borderColor: "#513981",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#513981",
    },
    "& input": {
      height: "0.7rem",
      fontFamily: "Poppins",
      fontSize: "14px",
    },
  },
  "& .MuiFormHelperText-root": {
    fontSize: "0.6rem",
    color: "black",
  },
});

const ColorButton = styled(Button)(() => ({
  width: "20%",
  fontSize: "1vw",
  fontFamily: "Poppins",
  backgroundImage: "linear-gradient(99deg, #6A1AC3 28.42%, #1D0A32 126.77%)",
  color: "white",
  "&:hover": {
    backgroundImage: "linear-gradient(99deg, #6A1AC3 28.42%, #1D0A32 126.77%)",
  },
}));
const customStyles = {
  row: {
    height: "100vh",
    "--bs-gutter-x": "0",
  },
  col: {
    height: "100%",
  },
};
const auth = getAuth();

export default function Signup() {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    confirmPassword: "",
  });
  const [showPass, setShowPass] = useState(false);
  const [users, setUsers] = useState(null);
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  const dispatch = useDispatch();
  const loading = useSelector((state) => state?.signupreducer?.loading);
  const user = useSelector((state) => state?.signupreducer?.user);
  const error = useSelector((state) => state?.signupreducer?.error);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const navigate = useNavigate();

  const isEmailValid = (email) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    return emailRegex.test(email);
  };
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (userAuth) => {
      if (userAuth) {
        // User logged in
        setUsers(userAuth);
      } else {
        // User logged out
        setUsers(null);
      }
    });
    return unsubscribe;
  }, []);
  useEffect(() => {
    const token = users?.accessToken;
    console.log(token, "tookennnnnnnnn");
    if (token) {
      dispatch(clearState());
    } else if (error) {
      toast.error(error);
      dispatch(clearState());
    }
  }, [user, error]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const newEmail = formData.email;

    if (!isEmailValid(newEmail)) {
      toast.error("Invalid Email");
      return;
    }
    if (formData.password.length < 6) {
      toast.error("Password must be at least 6 characters long.");
      return;
    }
    if (formData.password !== formData.confirmPassword) {
      toast.error("Passwords do not match.");
      return;
    }
    if (!formData.firstName) {
      toast.error("Please provide your first name.");
      return;
    }
    if (!formData.lastName) {
      toast.error("Please provide your last name.");
      return;
    }
    dispatch(signUp(formData));
    navigate("/signin");
    setFormData({
      email: "",
      password: "",
      firstName: "",
      lastName: "",
      confirmPassword: "",
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormControl className='col-12'>
        <div className='row' style={customStyles.row}>
          <div className='col-6' style={customStyles.col}>
            <img src={Signinimg} width='100%' height='100%' alt='Loading' />
          </div>
          <div className='col-6' style={customStyles.col}>
            <div className='w-100 h-100 justify-content-center align-items-center d-flex flex-column'>
              <Typography
                variant='h3'
                sx={{
                  color: "#503981",
                  fontWeight: "500",
                  fontFamily: "Poppins",
                  fontSize: "5.5vw",
                  fontStyle: "normal",
                  lineHeight: "70px",
                }}
              >
                Welcome
              </Typography>
              <Typography
                variant='p'
                sx={{
                  color: "#503981",
                  fontWeight: "400",
                  fontFamily: "Poppins",
                }}
              >
                Register your account
              </Typography>
              <CssTextField
                id='outlined-basic'
                label='First Name'
                name='firstName'
                variant='outlined'
                sx={{ marginTop: 2, width: "43%" }}
                focused
                placeholder='First Name'
                onChange={handleInputChange}
              />
              <CssTextField
                id='outlined-basic'
                label='Last Name'
                name='lastName'
                variant='outlined'
                sx={{ marginTop: 2, width: "43%" }}
                focused
                placeholder='Last Name'
                onChange={handleInputChange}
              />
              <CssTextField
                id='outlined-basic'
                label='Email'
                name='email'
                value={formData.email}
                variant='outlined'
                sx={{ marginTop: 2, width: "43%" }}
                focused
                placeholder='Email'
                InputProps={{
                  startAdornment: (
                    <MailOutlineIcon
                      sx={{ marginRight: 1, color: "#503981" }}
                    />
                  ),
                }}
                onChange={handleInputChange}
              />
              <CssTextField
                id='outlined-basic'
                label='Password'
                name='password'
                variant='outlined'
                sx={{ marginTop: 2, width: "43%" }}
                InputProps={{
                  startAdornment: (
                    <LockIcon sx={{ marginRight: 1, color: "#503981" }} />
                  ),
                  endAdornment: showPass ? (
                    <VisibilityIcon
                      onClick={() => setShowPass(!showPass)}
                      sx={{ cursor: "pointer", color: "#503981" }}
                    />
                  ) : (
                    <VisibilityOffIcon
                      onClick={() => setShowPass(!showPass)}
                      sx={{ cursor: "pointer", color: "#503981" }}
                    />
                  ),
                }}
                focused
                placeholder='Password'
                type={showPass ? "text" : "password"}
                value={formData.password}
                onChange={handleInputChange}
                helperText={
                  "Password must be more than 6 characters with uppercase, lowercase and contain special characters."
                }
              />
              <CssTextField
                id='outlined-basic'
                label='Confirm password'
                name='confirmPassword'
                variant='outlined'
                sx={{ marginTop: 2, width: "43%" }}
                InputProps={{
                  startAdornment: (
                    <LockIcon sx={{ marginRight: 1, color: "#503981" }} />
                  ),
                  endAdornment: showConfirmPass ? (
                    <VisibilityIcon
                      onClick={() => setShowConfirmPass(!showConfirmPass)}
                      sx={{ cursor: "pointer", color: "#503981" }}
                    />
                  ) : (
                    <VisibilityOffIcon
                      onClick={() => setShowConfirmPass(!showConfirmPass)}
                      sx={{ cursor: "pointer", color: "#503981" }}
                    />
                  ),
                }}
                focused
                placeholder='Confirm password'
                type={showConfirmPass ? "text" : "password"}
                value={formData.confirmPassword}
                onChange={handleInputChange}
              />

              <Link
                className='mt-2 d-flex justify-content-end w-45'
                style={{ textDecoration: "none" }}
                to={"/signin"}
              >
                <Typography
                  variant='p'
                  sx={{
                    color: "#503981",
                    fontSize: "1vw",
                    fontFamily: "Poppins",
                  }}
                >
                  Already have an account?
                </Typography>
              </Link>

              <ColorButton
                variant='contained'
                sx={{ marginTop: 3 }}
                type='submit'
                disabled={loading}
                onClick={handleSubmit}
              >
                Register{" "}
              </ColorButton>
            </div>
          </div>
        </div>
      </FormControl>
      <ToastContainer />
    </form>
  );
}
