import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import "./MobilePreview.css";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { ThreeDots } from "react-loader-spinner";
import { Document, Page, pdfjs } from "react-pdf";
import { fetchPdf } from "../../redux/action/action";

pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

function PrintPreview({
  selectedPageSize,
  showButton,
  showRadio,
  printModes,
  setPrintModes = () => {},
  handleInputChange,
}) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { pdfData, loading, error } = useSelector((state) => state.pdf);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [chapters, setChapters] = useState([]);
  const [downloadUrl, setDownloadUrl] = useState(null);

  useEffect(() => {
    if (numPages) {
      const defaultPrintModes = {};
      for (let i = 1; i <= numPages; i++) {
        defaultPrintModes[i] = "bw";
      }
      setPrintModes(defaultPrintModes);
    }
  }, [numPages]);

  useEffect(() => {
    dispatch(fetchPdf(id, selectedPageSize));
  }, [dispatch, id, selectedPageSize]);

  useEffect(() => {
    if (pdfData) {
      setDownloadUrl(pdfData);
    }
  }, [pdfData]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
    extractChapters(numPages);
  }

  async function extractChapters(numPages) {
    const chapterPositions = [];
    for (let i = 2; i <= numPages; i++) {
      const pageText = await extractTextFromPage(i);
      if (pageText.includes("Chapter")) {
        chapterPositions.push(i);
      }
    }
    setChapters(chapterPositions);
  }

  async function extractTextFromPage(pageNumber) {
    const pdfDoc = await pdfjs.getDocument({ url: pdfData }).promise;
    const page = await pdfDoc.getPage(pageNumber);
    const textContent = await page.getTextContent();
    return textContent.items.map((item) => item.str).join(" ");
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  function goToChapter(index) {
    const chapterPageNumber = chapters[index];
    setPageNumber(chapterPageNumber);
  }

  function getCurrentChapterNumber() {
    for (let i = 0; i < chapters.length; i++) {
      const currentChapterStart = chapters[i];
      const nextChapterStart = chapters[i + 1] || numPages + 1;
      if (pageNumber >= currentChapterStart && pageNumber < nextChapterStart) {
        return i + 1;
      }
    }
    return null;
  }

  const handleDownload = () => {
    if (downloadUrl) {
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = "yourFileName.pdf";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error("Download URL is not available");
    }
  };

  const handlePrintModeChange = (pageNumber, mode) => {
    setPrintModes((prevPrintModes) => ({
      ...prevPrintModes,
      [pageNumber]: mode,
    }));
    handleInputChange("printModes", printModes);
  };

  const handleAllPagesPrintModeChange = (mode) => {
    const updatedPrintModes = {};
    console.log(numPages, "numPages");
    for (let i = 1; i <= numPages; i++) {
      updatedPrintModes[i] = mode;
    }
    console.log(updatedPrintModes, "updatedPrintModes");
    setPrintModes(updatedPrintModes);
    handleInputChange("printModes", updatedPrintModes);
  };

  return (
    <>
      {error ? (
        <div
          className="error-message"
          style={{ textAlign: "center", marginTop: "30px" }}
        >
          {error}
        </div>
      ) : loading ? (
        <div className="loader-container">
          <ThreeDots color="#000" height={50} width={50} />
        </div>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Document file={pdfData} onLoadSuccess={onDocumentLoadSuccess}>
              <Page
                renderAnnotationLayer={false}
                renderTextLayer={false}
                pageNumber={pageNumber}
                width={400}
              />
            </Document>
            <div style={{ marginTop: "40px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <p>
                  Page {pageNumber || (numPages ? 1 : "--")} of{" "}
                  {numPages || "--"} -{" "}
                  {chapters.length > 0
                    ? `Chapter ${getCurrentChapterNumber() || "--"} of ${
                        chapters.length || "--"
                      }`
                    : null}
                </p>
                <br />
                <div className="button-box" style={{ display: "flex" }}>
                  <button
                    className="buttonpreview"
                    type="button"
                    disabled={pageNumber <= 1}
                    onClick={previousPage}
                  >
                    <ChevronLeftIcon />
                    Page
                  </button>
                  <button
                    className="buttonpreview"
                    type="button"
                    disabled={pageNumber >= numPages}
                    onClick={nextPage}
                  >
                    Page
                    <ChevronRightIcon />
                  </button>
                </div>
                {showRadio && (
                  <div className="print-mode" style={{ marginTop: "20px" }}>
                    <label style={{ marginLeft: "20px" }}>
                      <input
                        type="radio"
                        name={`printMode-${pageNumber}`}
                        value="bwAll"
                        onChange={() => handleAllPagesPrintModeChange("bw")}
                      />
                      B/W All
                    </label>
                    <label style={{ marginLeft: "20px" }}>
                      <input
                        type="radio"
                        name={`printMode-${pageNumber}`}
                        value="bw"
                        checked={printModes[pageNumber] === "bw"}
                        onChange={() => handlePrintModeChange(pageNumber, "bw")}
                      />
                      Black & White
                    </label>
                    <label style={{ marginLeft: "20px" }}>
                      <input
                        type="radio"
                        name={`printMode-${pageNumber}`}
                        value="color"
                        checked={printModes[pageNumber] === "color"}
                        onChange={() =>
                          handlePrintModeChange(pageNumber, "color")
                        }
                      />
                      Color
                    </label>
                    <label style={{ marginLeft: "20px" }}>
                      <input
                        type="radio"
                        name={`printMode-${pageNumber}`}
                        value="colorAll"
                        onChange={() => handleAllPagesPrintModeChange("color")}
                      />
                      Color All
                    </label>
                  </div>
                )}
                {downloadUrl && showButton && (
                  <button
                    onClick={handleDownload}
                    style={{
                      marginTop: "20px",
                      backgroundColor: "#503981",
                      borderRadius: "6px",
                      color: "white",
                      padding: "10px",
                    }}
                  >
                    Export PDF
                  </button>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default PrintPreview;
