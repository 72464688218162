export const FETCH_BOOK_REQUEST = "FETCH_BOOK_REQUEST";
export const FETCH_BOOK_SUCCESS = "FETCH_BOOK_SUCCESS";
export const FETCH_BOOK_FAILURE = "FETCH_BOOK_FAILURE";

export const CREATE_BOOK_REQUEST = "CREATE_BOOK_REQUEST";
export const CREATE_BOOK_SUCCESS = "CREATE_BOOK_SUCCESS";
export const CREATE_BOOK_FAILURE = "CREATE_BOOK_FAILURE";

export const ADD_CHAPTER_REQUEST = "ADD_CHAPTER_REQUEST";
export const ADD_CHAPTER_SUCCESS = "ADD_CHAPTER_SUCCESS";
export const ADD_CHAPTER_FAILURE = "ADD_CHAPTER_FAILURE";

export const FETCH_SINGLE_BOOK_REQUEST = "FETCH_SINGLE_BOOK_REQUEST";
export const FETCH_SINGLE_BOOK_SUCCESS = "FETCH_SINGLE_BOOK_SUCCESS";
export const FETCH_SINGLE_BOOK_FAILURE = "FETCH_SINGLE_BOOK_FAILURE";

export const DELETE_BOOK_REQUEST = "DELETE_BOOK_REQUEST";
export const DELETE_BOOK_FAILURE = "DELETE_BOOK_FAILURE";
export const DELETE_BOOK_SUCCESS = "DELETE_BOOK_SUCESS";

export const DELETE_CHAPTER_REQUEST = "DELETE_CHAPTER_REQUEST";
export const DELETE_CHAPTER_FAILURE = "DELETE_CHAPTER_FAILURE";
export const DELETE_CHAPTER_SUCCESS = "DELETE_CHAPTER_SUCCESS";

export const SAVE_CHAPTER_REQUEST = "SAVE_CHAPTER_REQUEST";
export const SAVE_CHAPTER_FAILURE = "SAVE_CHAPTER_FAILURE";
export const SAVE_CHAPTER_SUCCESS = "SAVE_CHAPTER_SUCCESS";

export const SAVE_DEDICATION_REQUEST ="SAVE_DEDICATION_REQUEST";
export const SAVE_DEDICATION_FAILURE ="SAVE_DEDICATION_FAILURE";
export const SAVE_DEDICATION_SUCCESS ="SAVE_DEDICATION_SUCCESS";

export const SAVE_INTRODUCTION_REQUEST ="SAVE_INTRODUCTION_REQUEST";
export const SAVE_INTRODUCTION_FAILURE ="SAVE_INTRODUCTION_FAILURE";
export const SAVE_INTRODUCTION_SUCCESS ="SAVE_INTRODUCTION_SUCCESS";

export const SAVE_FOREWORD_REQUEST ="SAVE_FOREWORD_REQUEST";
export const SAVE_FOREWORD_FAILURE ="SAVE_FOREWORD_FAILURE";
export const SAVE_FOREWORD_SUCCESS ="SAVE_FOREWORD_SUCCESS";

export const SAVE_PREFACE_REQUEST ="SAVE_PREFACE_REQUEST";
export const SAVE_PREFACE_FAILURE ="SAVE_PREFACE_FAILURE";
export const SAVE_PREFACE_SUCCESS ="SAVE_PREFACE_SUCCESS";

export const ADD_SUBCHAPTER_REQUEST = "ADD_SUBCHAPTER_REQUEST";
export const ADD_SUBCHAPTER_FAILURE = "ADD_SUBCHAPTER_FAILURE";
export const ADD_SUBCHAPTER_SUCCESS = "ADD_SUBCHAPTER_SUCCESS";
 
export const SAVE_SUBCHAPTER_REQUEST = "SAVE_SUBCHAPTER_REQUEST";
export const SAVE_SUBCHAPTER_FAILURE = "SAVE_SUBCHAPTER_FAILURE";
export const SAVE_SUBCHAPTER_SUCCESS = "SAVE_SUBCHAPTER_SUCCESS";

export const DELETE_SUBCHAPTER_REQUEST = "DELETE_SUBCHAPTER_REQUEST";
export const DELETE_SUBCHAPTER_FAILURE = "DELETE_SUBCHAPTER_FAILURE";
export const DELETE_SUBCHAPTER_SUCCESS = "DELETE_SUBCHAPTER_SUCCESS";

export const SIGNUP_USER_REQUEST = "SIGNUP_USER_REQUEST";
export const SIGNUP_USER_FAILURE = "SIGNUP_USER_FAILURE";
export const SIGNUP_USER_SUCCESS = "SIGNUP_USER_SUCCESS";

export const SIGNIN_USER_REQUEST = "SIGNIN_USER_REQUEST";
export const SIGNIN_USER_FAILURE = "SIGNIN_USER_FAILURE";
export const SIGNIN_USER_SUCCESS = "SIGNIN_USER_SUCCESS";

export const FORGET_PASSWORD_REQUEST = "FORGET_PASSWORD_REQUEST";
export const FORGET_PASSWORD_SUCCESS = "FORGET_PASSWORD_SUCCESS";
export const FORGET_PASSWORD_FAILURE = "FORGET_PASSWORD_FAILURE";

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";

export const VERIFY_EMAIL_REQUEST = 'VERIFY_EMAIL_REQUEST';
export const VERIFY_EMAIL_SUCCESS = 'VERIFY_EMAIL_SUCCESS';
export const VERIFY_EMAIL_FAILURE = 'VERIFY_EMAIL_FAILURE';

export const GOOGLE_SIGNIN_REQUEST = 'GOOGLE_SIGNIN_REQUEST';
export const GOOGLE_SIGNIN_SUCCESS = 'GOOGLE_SIGNIN_SUCCESS';
export const GOOGLE_SIGNIN_FAILURE = 'GOOGLE_SIGNIN_FAILURE';

export const SELECT_TEMPLATE = 'SELECT_TEMPLATE';

export const UPDATE_BOOK_REQUEST = "UPDATE_BOOK_REQUEST";
export const UPDATE_BOOK_SUCCESS = "UPDATE_BOOK_SUCCESS";
export const UPDATE_BOOK_FAILURE = "UPDATE_BOOK_FAILURE";

export const UPDATE_BOOK_STATUS_REQUEST = 'UPDATE_BOOK_STATUS_REQUEST';
export const UPDATE_BOOK_STATUS_SUCCESS = 'UPDATE_BOOK_STATUS_SUCCESS';
export const UPDATE_BOOK_STATUS_FAILURE = 'UPDATE_BOOK_STATUS_FAILURE';
// Order get for editor disabling//
export const FETCH_ORDER_SUCCESS = 'FETCH_ORDER_SUCCESS';
export const FETCH_ORDER_FAILURE = 'FETCH_ORDER_FAILURE';

export const UPLOAD_COVER_IMAGE_REQUEST='UPLOAD_COVER_IMAGE_REQUEST'
export const UPLOAD_COVER_IMAGE_SUCCESS='UPLOAD_COVER_IMAGE_SUCCESS'
export const UPLOAD_COVER_IMAGE_FAILURE ='UPLOAD_COVER_IMAGE_FAILURE'

export const UPLOAD_FRONT_COVER_IMAGE_REQUEST='UPLOAD_FRONT_COVER_IMAGE_REQUEST'
export const UPLOAD_FRONT_COVER_IMAGE_SUCCESS='UPLOAD_FRONT_COVER_IMAGE_SUCCESS'
export const UPLOAD_FRONT_COVER_IMAGE_FAILURE ='UPLOAD_FRONT_COVER_IMAGE_FAILURE'

export const FETCH_PDF_REQUEST = 'FETCH_PDF_REQUEST';
export const FETCH_PDF_SUCCESS = 'FETCH_PDF_SUCCESS';
export const FETCH_PDF_FAILURE = 'FETCH_PDF_FAILURE';
export const SET_SELECTED_PAGE_SIZE='SET_SELECTED_PAGE_SIZE'