// UserTable.js
import React,{useState} from 'react';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function InvoicesTable({ payments}) {
    console.log(payments)
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 600 }} aria-label="customized table">
        <TableHead>
          <TableRow>
          <TableCell align='center' style={{ fontWeight: 'bold' }}>#</TableCell>
            <TableCell style={{ fontWeight: 'bold' }}>Amount</TableCell>
            <TableCell style={{ fontWeight: 'bold' }}>Currency</TableCell>
            <TableCell style={{ fontWeight: 'bold' }}>Customer</TableCell>
            <TableCell style={{ fontWeight: 'bold' }}>Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {payments.map((row, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 }, height: '40px' }}
            >
              <TableCell component="th" scope="row" sx={{ padding: 0 }} align='center'>
                {index + 1}
              </TableCell>
              <TableCell>{row.amount}</TableCell>
              <TableCell>{row.currency}</TableCell>
              <TableCell>
                {row.token.email}
              </TableCell>
              <TableCell>{row.createdAt.slice(0, 10)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default InvoicesTable;
