import React, { useState } from "react";
import "./sidebar.css";
import menu from "./menu.svg";
import profile from "./profile.svg";
import logout from "./logout.svg";
import book from "./book.svg";
import upload from "./upload.svg";
import settingIcon from "./settings.svg";
import { Link, useNavigate } from "react-router-dom";
import Modal from "../Modal";
import { getAuth, signOut } from "firebase/auth";

function Sidebar({isActive,setIsActive}) {
  const [isModalOpen, setModalOpen] = useState(false);
  const [headingState, setHeadingState] = useState(null);
  const [modalMode, setModalMode] = useState("");
  const openModal = (mode) => {
    setModalMode(mode);
    setModalOpen(true);
  };
  const toggleSidebar = () => {
    setIsActive(!isActive);
  };
  const navigate = useNavigate();
  const handleSignOut = () => {
    localStorage.clear();
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        navigate("/signin");
      })
      .catch((error) => {
        console.error("Error signing out:", error);
      });
  };


  return (
    <>
      <div className='sidebar-border'>
        <nav className={`nav ${isActive ? "active" : ""}`}>
          <ul>
            <li onClick={toggleSidebar}>
              <Link className='toggle'>
                <span className='icon'>
                  <img src={menu} />
                </span>
                <span className='title'>Menu</span>
              </Link>
            </li>
            <li>
              <Link to='/admin'>
                <span className='icon'>
                  <img src={profile} />
                </span>
                <span className='title'>Admin</span>
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  openModal("create");
                  setHeadingState("let's create new journey");
                }}
              >
                <span className='icon'>
                  <img src={book} />
                </span>
                <span className='title'>Create Book</span>
              </Link>
            </li>
            <li>
              <Link onClick={() => {
                  openModal("upload");
                  setHeadingState("Upload a Book");
                }}>
                <span className='icon'>
                  <img src={upload} />
                </span>
                <span className='title'>Upload Book</span>
              </Link>
            </li>
            <li>
              <Link to={'/settings'}>
                <span className='icon'>
                  <img src={settingIcon} />
                </span>
                <span className='title'>Setting</span>
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  handleSignOut();
                }}
              >
                <span className='icon'>
                  <img src={logout} />
                </span>
                <span className='title'>Sign Out</span>
              </Link>
            </li>
          </ul>
        </nav>
        <Modal
          open={isModalOpen}
          setModalOpen={setModalOpen}
          heading={headingState}
          mode={modalMode}
        />
      </div>
    </>
  );
}

export default Sidebar;



