import React, { useState } from "react";
import axios from "axios";
import StripeCheckout from "react-stripe-checkout";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "./checkout.css";

const Checkout = () => {
  const location = useLocation();
  const bookId = useParams();
  const navigate = useNavigate();
  const { selectedValues } = location.state || {};
  console.log(selectedValues);
  const [shipment, setShipment] = useState({
    shipperName: "John Doe",
    shipperAddress: "123 Default Shipper Street",
    recipientName: "",
    recipientNumber: "",
    recipientAddress: "",
    recipientCity: "",
    recipientState: "",
    recipientZipCode: "",
    recipientCountry: "",
  });
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [length, height] = selectedValues.bookSize.type.split("x");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setShipment({
      ...shipment,
      [name]: value,
    });
  };

  const createOrder = async () => {
    setError(null);
    const shipmentData = {
      recipientName: shipment.recipientName,
      recipientNumber: shipment.recipientNumber,
      recipientAddress: shipment.recipientAddress,
      recipientCity: shipment.recipientCity,
      recipientState: shipment.recipientState,
      recipientZipCode: shipment.recipientZipCode,
      recipientCountry: shipment.recipientCountry,
    };
    console.log(shipmentData);
    const combinedData = {
      ...selectedValues,
      shipmentResponse: response?.ShipmentResponse,
      shipmentData: shipmentData,
    };
    const URL = "https://app.simplymemoirs.com/api";
    try {
      const res = await axios.post(`${URL}/orders/${bookId.id}`, combinedData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setResponse(res.data);
      console.log(res.data);
    } catch (error) {
      console.error(
        "Error creating shipment:",
        error.response ? error.response.data : error.message
      );
      setError(error.response ? error.response.data : { error: error.message });
    }
  };

  const handleToken = (token) => {
    const authToken = localStorage.getItem("token");
    const URL = "https://app.simplymemoirs.com/api";
    axios
      .post(
        `${URL}/stripe/${bookId.id}`,
        {
          token,
          amount: totalAmount * 100, // Amount in cents
          currency: "USD",
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      .then((response) => {
        console.log("Payment successful:", response.data);
        navigate("/paymentsuccessful");
        createOrder();
      })
      .catch((error) => {
        if (error.response.status === 401) {
          toast.error("Payment already exists for this book");
        } else {
          toast.error("An error occurred while processing payment");
        }
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);
    try {
      const URL = "https://app.simplymemoirs.com/api";
      const res = await axios.post(`${URL}/create-shipment`, {
        ShipmentRequest: {
          Request: {
            RequestOption: "nonvalidate",
            TransactionReference: {
              CustomerContext: "Your Customer Context",
            },
          },
          Shipment: {
            Shipper: {
              Name: shipment.shipperName,
              ShipperNumber: "0717RF",
              Address: {
                AddressLine: shipment.shipperAddress,
                City: "Springfield",
                StateProvinceCode: "IL",
                PostalCode: "62701",
                CountryCode: "US",
              },
            },
            ShipTo: {
              Name: shipment.recipientName,
              Phone: { Number: shipment.recipientNumber },
              Address: {
                AddressLine: shipment.recipientAddress,
                City: shipment.recipientCity,
                StateProvinceCode: shipment.recipientState,
                PostalCode: shipment.recipientZipCode,
                CountryCode: shipment.recipientCountry,
              },
            },
            Service: {
              Code: "03",
              Description: "Express",
            },
            Package: {
              Description: " ",
              Packaging: {
                Code: "02",
                Description: "Nails",
              },
              Dimensions: {
                UnitOfMeasurement: {
                  Code: "IN",
                  Description: "Inches",
                },
                Length: length.toString(),
                Width: "3",
                Height: height.toString(),
              },
              PackageWeight: {
                UnitOfMeasurement: {
                  Code: "LBS",
                  Description: "Pounds",
                },
                Weight: selectedValues.bookWeight.price.toString(),
              },
            },
            PaymentInformation: {
              ShipmentCharge: {
                Type: "01",
                BillShipper: {
                  AccountNumber: "0717RF",
                },
              },
            },
          },
        },
      });
      setResponse(res.data);
      setLoading(false);
      console.log(res);

      // Show success toast notification
      toast.success("Shipment created successfully!");
    } catch (error) {
      setLoading(false);
      console.error(
        "Error creating shipment:",
        error.response ? error.response.data : error.message
      );

      // Show error toast notification
      toast.error(
        "Failed to create shipment. Please try again with valid data."
      );

      setError(error.response ? error.response.data : { error: error.message });
    }
  };

  const bookAmount = parseFloat(selectedValues.price); // Ensure bookAmount is a number
  const shippingAmount =
    parseFloat(
      response?.ShipmentResponse?.ShipmentResults?.ShipmentCharges?.TotalCharges
        ?.MonetaryValue
    ) || 0; // Ensure shippingAmount is a number
  const totalAmount = bookAmount + shippingAmount; // Sum the numbers

  return (
    <div className="checkout-container">
      <div className="shipping-form">
        <h1>Shipping</h1>
        <p>Please enter your shipping details.</p>
        <hr />
        <form onSubmit={handleSubmit} className="form">
          <div className="fields fields--2">
            <label className="field">
              <span className="field__label" htmlFor="firstname">
                Your Name
              </span>
              <input
                className="field__input"
                type="text"
                name="recipientName"
                value={shipment.recipientName}
                onChange={handleChange}
                placeholder="Jane Smith"
              />
            </label>
            <label className="field">
              <span className="field__label" htmlFor="phonenumber">
                Phone Number
              </span>
              <input
                className="field__input"
                type="text"
                name="recipientNumber"
                value={shipment.recipientNumber}
                onChange={handleChange}
                placeholder="+1-4444444444"
              />
            </label>
          </div>
          <label className="field">
            <span className="field__label" htmlFor="address">
              Address
            </span>
            <input
              className="field__input"
              type="text"
              name="recipientAddress"
              value={shipment.recipientAddress}
              onChange={handleChange}
              placeholder="123 Main St"
            />
          </label>
          <label className="field">
            <span className="field__label" htmlFor="country">
              Country
            </span>
            <input
              className="field__input"
              type="text"
              name="recipientCountry"
              value={shipment.recipientCountry}
              onChange={handleChange}
              placeholder="US"
            />
          </label>
          <div className="fields fields--3">
            <label className="field">
              <span className="field__label" htmlFor="zipcode">
                Zip code
              </span>
              <input
                className="field__input"
                type="text"
                name="recipientZipCode"
                value={shipment.recipientZipCode}
                onChange={handleChange}
                placeholder="10001"
              />
            </label>
            <label className="field">
              <span className="field__label" htmlFor="city">
                City
              </span>
              <input
                className="field__input"
                type="text"
                name="recipientCity"
                value={shipment.recipientCity}
                onChange={handleChange}
                placeholder="Metropolis"
              />
            </label>
            <label className="field">
              <span className="field__label" htmlFor="state">
                State
              </span>
              <input
                className="field__input"
                type="text"
                name="recipientState"
                value={shipment.recipientState}
                onChange={handleChange}
                placeholder="NY"
              />
            </label>
          </div>
          <hr />
          <button className="button" type="submit" disabled={loading}>
            Calculate Shipping
          </button>
        </form>
        <ToastContainer />
      </div>
      <div className="payment-summary">
        <h1>Total Amount</h1>
        <div className="summary-item">
          <p>Book Amount</p>
          <p>${selectedValues.price}</p>
        </div>
        <div className="summary-item">
          <p>Shipping Amount</p>
          <p>${shippingAmount}</p>
        </div>
        <hr />
        <div className="summary-item total">
          <b>Total:</b>
          <b>${totalAmount}</b>
        </div>
        <StripeCheckout
          stripeKey={process.env.REACT_APP_STRIPE_KEY}
          name="Order Payment"
          amount={totalAmount * 100} // Amount to be charged (in cents)
          token={handleToken}
        >
          <button
            className="button"
            type="button"
            disabled={!response || shippingAmount === 0}
          >
            Continue
          </button>
        </StripeCheckout>
      </div>
    </div>
  );
};

export default Checkout;
