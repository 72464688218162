import React from "react";
import "./settings.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";

export default function Settings() {
  const centerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "calc(100vh - 100px)",
  };
  return (
    <div style={centerStyle}>
      <div style={{ display: "flex" }}>
        <h1 style={{ marginRight: "10px" }}>Work in progress</h1>
        <FontAwesomeIcon
          icon={faCog}
          className='rotating-icon'
          style={{ fontSize: "3em" }}
        />
      </div>
    </div>
  );
}
