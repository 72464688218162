import React from 'react';
import './Toolbar.css';

const Toolbar = ({ top, left, onBoldClick, onItalicClick, onUnderlineClick, onChangeFontSize, onChangeFontFamily, onChangeColor }) => {
  const handleFontSizeChange = (e) => {
    onChangeFontSize(e.target.value);
  };

  const handleFontFamilyChange = (e) => {
    onChangeFontFamily(e.target.value);
  };

  const handleColorChange = (e) => {
    onChangeColor(e.target.value);
    console.log(e.target.value);
  };

  return (
    <div className="toolbar" style={{ top, left }}>
      <button onClick={onBoldClick}><b>B</b></button>
      <button onClick={onItalicClick}><i>I</i></button>
      <button onClick={onUnderlineClick}><u>U</u></button>
      <select onChange={handleFontSizeChange}>
        <option value="12px">12px</option>
        <option value="14px">14px</option>
        <option value="16px">16px</option>
        <option value="18px">18px</option>
        <option value="20px">20px</option>
        <option value="72px">72px</option>
      </select>
      <select onChange={handleFontFamilyChange}>
        <option value="Arial">Arial</option>
        <option value="Verdana">Verdana</option>
        <option value="Helvetica">Helvetica</option>
        <option value="Times New Roman">Times New Roman</option>
        <option value="Courier New">Courier New</option>
        <option value="Georgia">Georgia</option>
      </select>
      <input type="color" onChange={handleColorChange} value="#000000" />
    </div>
  );
};

export default Toolbar;
