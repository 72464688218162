// import { combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import reducer from "./reducer/reducer";
import signinreducer from "./reducer/signinreducer";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import signupreducer from "./reducer/signupreducer";
import createbookreducer from "./reducer/createbookreducer";
import addchapterreducer from "./reducer/addchapterreducer";
import addsubchapterreducer from "./reducer/addsubchapterreducer";
import authreducer from "./reducer/authreducer";
import resetPasswordReducer from "./reducer/resetreducer";
import verifyEmail from "./reducer/verifyemailreducer";
import templateReducer from "./reducer/templatereducer";
import signinReducer from "./reducer/googlereducer"
import bookReducer from "./reducer/updatebookreducer"
import bookcoverReducer from "./reducer/bookcoverreducer"
import orderstatususerreducer from "./reducer/orderstatususerreducer"
import editororderReducer from "./reducer/getordereditorreducer"
import bookCoverReducer from "./reducer/coverimagereducer"
import frontBookCoverReducer from "./reducer/frontcoverimagereducer"
import pdfReducer from './reducer/pdfreducer';
import PageSizeReducer from './reducer/pagesizereducer'

const rootReducer = combineReducers({
  reducer,
  signinreducer,
  signupreducer,
  createbookreducer,
  addchapterreducer,
  addsubchapterreducer,
  bookcover:bookcoverReducer, 
  auth: authreducer,
  resetPassword: resetPasswordReducer,
  verifyingEmail:verifyEmail,
  template: templateReducer,
  Google:signinReducer,
  updatebookreducer:bookReducer,
  orderstatus:orderstatususerreducer,
  editororder:editororderReducer,
  bookCover:bookCoverReducer,
  frontCover:frontBookCoverReducer,
  pdf:pdfReducer,
  pageSize:PageSizeReducer
  // Add other reducers here
});

const store = configureStore({ reducer: rootReducer, middleware: [thunk] });

export default store;
