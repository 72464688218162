import {
  CREATE_BOOK_REQUEST,
  CREATE_BOOK_SUCCESS,
  CREATE_BOOK_FAILURE,
} from "../actiontype/actiontype";

let initialState = {
  newBook: {},
  loading: false,
  error: "",
};

const createbookreducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_BOOK_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case CREATE_BOOK_SUCCESS:
      return {
        ...state,
        loading: false,
        newBook: action.payload,
      };

    case CREATE_BOOK_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "CLEAR_STATE":
      return {
        ...state,
        loading: false,
        error: "",
        newBook: {},
      };
    default:
      return { ...state };
  }
};

export default createbookreducer;
