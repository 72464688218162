import React from "react";
import { Link } from "react-router-dom";
import "./loading.css";

const ReactBookLoader = () => {
  return (
    <div
      className='container'
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <div className='book'>
        <div className='inner'>
          <div className='left'></div>
          <div className='middle'></div>
          <div className='right'></div>
        </div>
        <ul>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
        {/* <Link
          className='dribbble'
          href='https://dribbble.com/shots/7199149-Book-Loader'
          target='_blank'
        >
          <img src='https://simplymemoirs.s3.us-west-1.amazonaws.com/images/simply%20logo.png' />
        </Link> */}
      </div>
    </div>
  );
};

export default ReactBookLoader;
