import {
  SIGNUP_USER_REQUEST,
  SIGNUP_USER_FAILURE,
  SIGNUP_USER_SUCCESS,
} from "../actiontype/actiontype";

let initialState = {
  user: {},
  loading: false,
  error: "",
};

const signupreducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGNUP_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case SIGNUP_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
      };

    case SIGNUP_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "CLEAR_STATE":
      return {
        ...state,
        loading: false,
        error: "",
      };
    default:
      return { ...state };
  }
};

export default signupreducer;
