import React, { useState } from "react";

import { continueAi, customPromptAI } from "../api/api";
import { stripHtmlTags } from "../utils/helper";
import { ToastContainer, toast } from "react-toastify";
import { CopyToClipboard } from "react-copy-to-clipboard";

import "react-quill/dist/quill.snow.css";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import LinesButton from "./LineButton";
import YearButton from "./YearButton";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import ResponsePopup from "./ResponsePopup";

function AiResponse({ value }) {
  const [isLoading, setIsLoading] = useState(false);
  const [customPrompt, setCustomPrompt] = useState("");
  const [responseAI, setResponseAI] = useState("");
  const [copyText, setCopyText] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };
  const handleCustom = async () => {
    try {
      setIsLoading(true);
      const suggest = stripHtmlTags(value);
      const res = await customPromptAI(suggest, customPrompt);
      setIsLoading(false);
      if (res.data === "") {
        toast.error("Please try again, Something went wrong");
        return;
      }
      setResponseAI(res.data);
    } catch (err) {
      //   toast.error("Something went wrong");
      setIsLoading(false);
    }
  };
  return (
    <div
      className='col-5 d-flex flex-column'
      style={{
        backgroundColor: "white",
        borderRadius: "20px",
        fontFamily: "poppins",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "7px 7px 7px 7px",
        }}
      >
        <Typography
          align='left'
          variant='h6'
          className='mb-3'
          sx={{ fontFamily: "poppins" }}
        >
          Custom Prompt
        </Typography>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <TextField
            id='outlined-multiline-static'
            multiline
            color='primary'
            sx={{ width: "83%", fontFamily: "poppins" }}
            maxRows={2}
            value={customPrompt}
            onChange={(e) => setCustomPrompt(e.target.value)}
            focused
          />
          <Button
            onClick={handleCustom}
            sx={{ border: "2px solid #503981", color: "#503981" }}
            disabled={customPrompt === "" || isLoading ? true : false}
          >
            Go
          </Button>
        </div>
        <Typography
          align='left'
          variant='h6'
          className='mb-3 mt-3'
          sx={{ fontFamily: "poppins" }}
        >
          Quotation & Poetry
        </Typography>

        <div className='row'>
          <div className='col-6'>
            <LinesButton
              title={"Quotation"}
              val={value}
              setResponseAI={setResponseAI}
            />
          </div>
          <div className='col-6'>
            <LinesButton
              title={"Poetry"}
              val={value}
              setResponseAI={setResponseAI}
            />
          </div>
        </div>
        <div className='mt-3'>
          <Typography
            align='left'
            variant='h6'
            className='mb-3'
            sx={{ fontFamily: "poppins" }}
          >
            Current Event
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div className='w-45 mb-4' style={{ width: "45%" }}>
              <YearButton
                action={"Write about a current event that happened in"}
                setResponseAI={setResponseAI}
              />
            </div>
            <div className='w-45 mb-4' style={{ width: "45%" }}>
              <YearButton
                action={"Write about the political climate during"}
                setResponseAI={setResponseAI}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div className='w-45 mb-4' style={{ width: "45%" }}>
              <YearButton
                action={"Write about cost of living during"}
                setResponseAI={setResponseAI}
              />
            </div>
            <div className='w-45' style={{ width: "45%" }}>
              <YearButton
                action={"Songs popular during"}
                setResponseAI={setResponseAI}
              />
            </div>
          </div>
        </div>
        <div className=''>
          <div className='w-70'>
            <Typography
              align='left'
              variant='h6'
              className='mb-3'
              sx={{ fontFamily: "poppins" }}
            >
              REPHRASING & EXTEND
            </Typography>

            <LinesButton
              title={"Rephrasing"}
              val={value}
              setResponseAI={setResponseAI}
              sx={{ width: "45%" }}
            />
          </div>
        </div>
        <div className='w-100 mt-3'>
          <Typography align='left' variant='h6' className='mb-3'>
            Response
          </Typography>
          <TextField
            id='outlined-multiline-static'
            multiline
            rows={4}
            fullWidth
            color='primary'
            value={responseAI}
            InputProps={{
              endAdornment: (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <CopyToClipboard
                    text={responseAI}
                    onCopy={() => {
                      setCopyText(true);
                    }}
                  >
                    <div style={{ marginBottom: "8px" }}>
                      <Tooltip title={copyText ? "Copied" : "Copy"}>
                        <ContentCopyOutlinedIcon sx={{ cursor: "pointer" }} />
                      </Tooltip>
                    </div>
                  </CopyToClipboard>

                  <div style={{ marginTop: "8px" }}>
                    <Tooltip title='Open in new window'>
                      <OpenInNewIcon
                        sx={{ cursor: "pointer" }}
                        onClick={handleOpenPopup}
                      />
                    </Tooltip>
                  </div>
                </div>
              ),
            }}
            sx={{
              alignItems: "start",
              border: "2px solid #503981",
              borderRadius: "6px",
            }}
          />
        </div>
      </div>
      {isPopupOpen && (
        <ResponsePopup response={responseAI} onClose={handleClosePopup} />
      )}
    </div>
  );
}

export default AiResponse;
