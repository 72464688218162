import axios from "axios";
import { createBook } from "../redux/action/action";
// const URL = "https://fuzzy-bass-veil.cyclic.app";
// const URL = "http://localhost:3001/api";
const URL = "https://app.simplymemoirs.com/api";
// const URL = "http://localhost:3001/api";
// export const paraphrase = async (text) => {
//   const body = {
//     prompt: text,
//   };
//   const { data } = await axios.post(
//     "https://fair-woolens-toad.cyclic.cloud/paraphrasing",
//     body
//   );
//   return data;
// };

// export const continueAi = async (prompt) => {
//   const body = {
//     prompt,
//   };
//   const { data } = await axios.post(
//     "https://fair-woolens-toad.cyclic.cloud/continuewriting",
//     body
//   );
//   return data;
// };

// export const createNewBook = async (data) => {
//   const res = await axios.post("http://localhost:3001/createnewbook", data);
//   return function (dispatch) {
//     dispatch(createBook(res));
//   };
// };

export const paraphrase = async (text) => {
  const body = {
    prompt: text,
  };
  const { data } = await axios.post(`${URL}/paraphrasing`, body);
  return data;
};

export const continueAi = async (prompt) => {
  const body = {
    prompt,
  };
  const { data } = await axios.post(`${URL}/continuewriting`, body);
  return data;
};

export const createNewBook = async (data) => {
  const res = await axios.post(`${URL}/createnewbook`, data);
  return function (dispatch) {
    dispatch(createBook(res));
  };
};

export const actionAI = async (action, prompt) => {
  const body = {
    action,
    prompt,
  };
  // console.log(body);
  if (prompt && action) {
    const { data } = await axios.post(`${URL}/action`, body);
    return data;
  } else if (prompt && action === undefined) {
    console.log("checking");
    const { data } = await axios.post(`${URL}/action`, {
      prompt,
    });
    return data;
  }
};

export const customPromptAI = async (prompt, action) => {
  const slicePrompt = prompt.slice(0, 500);
  const body = {
    prompt: slicePrompt,
    action,
  };
  console.log(slicePrompt, "after Slice ");
  const { data } = await axios.post(`${URL}/customprompt`, body);
  return data;
};

export const yearActionAI = async (prompt) => {
  const body = {
    prompt,
  };
  // console.log(body);
  if (prompt) {
    const { data } = await axios.post(`${URL}/yearactionai`, body);
    return data;
  }
};
