import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchPdf } from "../../redux/action/action";
import Box from "@mui/material/Box";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import Button from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import PrintIcon from "@mui/icons-material/Print";
import ScreenRotationOutlinedIcon from "@mui/icons-material/ScreenRotationOutlined";
import FeedIcon from "@mui/icons-material/Feed";
import DialogContent from "@mui/material/DialogContent";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { ToastContainer, toast } from "react-toastify";
import { CiDeliveryTruck } from "react-icons/ci";
import { MdOutlineMenuBook } from "react-icons/md";
import {
  BookTypeDetail,
  PrintQualityDetail,
  PaperWeightDetail,
  BookSizeDetail,
  OrientationDetail,
  DeliveryMethodDetail,
  CoverFinishDetail,
  BookWeightDetail,
  CopiesDetails,
  PagesColorDetail,
} from "./Detail";

const steps = [
  {
    label: "Step 1",
    detailComponent: <BookTypeDetail />,
    completed: false,
  },
  {
    label: "Step 2",
    detailComponent: <PrintQualityDetail />,
    completed: false,
  },
  {
    label: "Step 3",
    detailComponent: <PaperWeightDetail />,
    completed: false,
  },
  {
    label: "Step 4",
    detailComponent: <BookSizeDetail />,
    completed: false,
  },
  {
    label: "Step 5",
    detailComponent: <OrientationDetail />,
    completed: false,
  },
  {
    label: "Step 6",
    detailComponent: <BookWeightDetail />,
    completed: false,
  },
  {
    label: "Step 7",
    detailComponent: <DeliveryMethodDetail />,
    completed: false,
  },
  {
    label: "Step 8",
    detailComponent: <CoverFinishDetail />,
    completed: false,
  },
  {
    label: "Step 9",
    detailComponent: <PagesColorDetail />,
    completed: false,
  },
  {
    label: "Step 10",
    detailComponent: <CopiesDetails />,
    completed: false,
  },
];

export default function Orders() {
  const [activeStep, setActiveStep] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [bookDetails, setBookDetails] = useState({
    bookType: { type: null, price: 0 },
    printQuality: { type: null, price: 0 },
    paperWeight: { type: null, price: 0 },
    bookSize: { type: null, price: 0 },
    orientation: { type: null, price: 0 },
    bookWeight: { type: null, price: 0 },
    deliveryMethod: { type: null, price: 0 },
    coverFinish: { type: null, price: 0 },
  });

  const bookId = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedPageSize = useSelector(
    (state) => state.pageSize.selectedPageSize
  );
  useEffect(() => {
    dispatch(fetchPdf(bookId.id, selectedPageSize));
  }, [dispatch, bookId.id, selectedPageSize]);

  function separatePages(pages) {
    let blackAndWhiteCount = 0;
    let colorCount = 0;
    let totalCount = 0;

    for (let key in pages) {
      if (pages.hasOwnProperty(key)) {
        totalCount++;
        if (pages[key] === "bw") {
          blackAndWhiteCount++;
        } else if (pages[key] === "color") {
          colorCount++;
        }
      }
    }

    return {
      blackAndWhite: blackAndWhiteCount,
      color: colorCount,
      total: totalCount,
    };
  }

  const calculatePrice = (bookDetails) => {
    console.log(bookDetails, "bookDetails");

    // Constants for cost per page
    const bwPageCost =
      bookDetails.printQuality.type === "Economy" ? 0.02 : 0.03;
    const colorPageCost =
      bookDetails.printQuality.type === "Economy" ? 0.1 : 0.12;
    const fixedCost = 24.5;

    // Sum up the base prices from bookDetails
    let totalPrice = 0;
    totalPrice += bookDetails.bookType.price || 0;
    totalPrice += bookDetails.printQuality.price || 0;
    totalPrice += bookDetails.paperWeight.price || 0;
    totalPrice += bookDetails.bookSize.price || 0;
    totalPrice += bookDetails.orientation.price || 0;
    totalPrice += bookDetails.deliveryMethod.price || 0;
    totalPrice += bookDetails.coverFinish.price || 0;

    // Calculate the number of black and white and color pages
    const pages = bookDetails.printModes?.type;
    const { blackAndWhite, color, total } = separatePages(pages);

    // Calculate the cost of pages
    const pageCost = blackAndWhite * bwPageCost + color * colorPageCost;

    // Calculate the margin factor based on the number of copies
    const numCopies = parseInt(bookDetails.noofcopies?.type);
    let marginFactor;

    if (numCopies < 24) {
      marginFactor = 0.4;
    } else if (numCopies >= 24 && numCopies <= 49) {
      marginFactor = 0.425;
    } else if (numCopies >= 50 && numCopies <= 99) {
      marginFactor = 0.45;
    } else if (numCopies >= 100 && numCopies <= 199) {
      marginFactor = 0.475;
    } else if (numCopies >= 200 && numCopies <= 299) {
      marginFactor = 0.5;
    } else if (numCopies >= 300 && numCopies <= 499) {
      marginFactor = 0.525;
    } else if (numCopies >= 500) {
      marginFactor = 0.55;
    }

    // Calculate the total book cost
    const bookCost = fixedCost + pageCost + totalPrice;

    // Calculate the final price per book
    const finalPricePerBook = bookCost / marginFactor;

    return finalPricePerBook.toFixed(2);
  };

  const handleCheckout = () => {
    const selectedValues = {
      bookType: bookDetails.bookType,
      printQuality: bookDetails.printQuality,
      paperWeight: bookDetails.paperWeight,
      bookSize: bookDetails.bookSize,
      orientation: bookDetails.orientation,
      bookWeight: bookDetails.bookWeight,
      deliveryMethod: bookDetails.deliveryMethod,
      coverFinish: bookDetails.coverFinish,
      noofcopies: bookDetails.noofcopies,
      printModes: bookDetails.printModes,
      price: totalPrice,
    };
    navigate(`/checkout/${bookId.id}`, { state: { selectedValues } }); // Navigate to Checkout with selected values
  };

  const handleNext = () => {
    if (!steps[activeStep].completed) {
      toast.error("Please select the option before moving to next step");
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleCalculatePrice = () => {
    const price = calculatePrice(bookDetails);
    console.log(price);
    setTotalPrice(price);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleDetailChange = (detailName, value, price) => {
    setBookDetails((prevDetails) => ({
      ...prevDetails,
      [detailName]: { type: value, price: price },
    }));
    steps[activeStep].completed = true;
  };

  const theme = createTheme({
    palette: {
      mode: "light",
      background: {
        default: "#ffffff", // Set the background color for dark mode
      },
    },
  });
  return (
    <>
      <ThemeProvider theme={theme}>
        <div
          style={{
            width: "100%",
            height: "90vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "black",
            marginTop: "50px",
          }}
        >
          <div
            style={{ width: "60%", display: "flex", flexDirection: "column" }}
          >
            <div style={{ display: "flex" }}>
              <Box
                sx={{
                  width: "40%",
                  display: "flex",
                  flexDirection: "column",
                  // marginTop:"30px"
                }}
              >
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "60%",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        alignContent: "center",
                        width: "100%",
                        border: "0.5px solid #EAEAEA",
                        padding: "8px",
                        borderRadius: "5px",
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <p style={{ marginBottom: "0px" }}>Book Type</p>
                      <MenuBookIcon />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        alignContent: "center",
                        width: "100%",
                        border: "0.5px solid #EAEAEA",
                        padding: "8px",
                        borderRadius: "5px",
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <p style={{ marginBottom: "0px" }}>Print Quality</p>
                      <PrintIcon />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        alignContent: "center",
                        width: "100%",
                        border: "0.5px solid #EAEAEA",
                        padding: "8px",
                        borderRadius: "5px",
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <p style={{ marginBottom: "0px" }}>Paper Weight</p>
                      <FeedIcon />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        alignContent: "center",
                        width: "100%",
                        border: "0.5px solid #EAEAEA",
                        padding: "8px",
                        borderRadius: "5px",
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <p style={{ marginBottom: "0px" }}>Book Size</p>
                      <MenuBookIcon />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        alignContent: "center",
                        width: "100%",
                        border: "0.5px solid #EAEAEA",
                        padding: "8px",
                        borderRadius: "5px",
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <p style={{ marginBottom: "0px" }}>Orientation</p>
                      <ScreenRotationOutlinedIcon />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        alignContent: "center",
                        width: "100%",
                        border: "0.5px solid #EAEAEA",
                        padding: "8px",
                        borderRadius: "5px",
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <p style={{ marginBottom: "0px" }}>Book Weight</p>
                      <ScreenRotationOutlinedIcon />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        alignContent: "center",
                        width: "100%",
                        border: "0.5px solid #EAEAEA",
                        padding: "8px",
                        borderRadius: "5px",
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <p style={{ marginBottom: "0px" }}>Delivery Method</p>
                      <CiDeliveryTruck
                        style={{ fontWeight: "700", fontSize: "30px" }}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        alignContent: "center",
                        width: "100%",
                        border: "0.5px solid #EAEAEA",
                        padding: "8px",
                        borderRadius: "5px",
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <p style={{ marginBottom: "0px" }}>Cover Finish </p>
                      <MdOutlineMenuBook />
                    </div>
                    {/* <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        alignContent: "center",
                        width: "100%",
                        border: "0.5px solid #EAEAEA",
                        padding: "8px",
                        borderRadius: "5px",
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <p style={{ marginBottom: "0px" }}>Num of Pages </p>
                      <MdOutlineMenuBook />
                    </div> */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        alignContent: "center",
                        width: "100%",
                        border: "0.5px solid #EAEAEA",
                        padding: "8px",
                        borderRadius: "5px",
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <p style={{ marginBottom: "0px" }}>Page Color </p>
                      <MdOutlineMenuBook />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        alignContent: "center",
                        width: "100%",
                        border: "0.5px solid #EAEAEA",
                        padding: "8px",
                        borderRadius: "5px",
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <p style={{ marginBottom: "0px" }}>Copies</p>
                      <MdOutlineMenuBook />
                    </div>
                  </div>
                  <div style={{ width: "40%", paddingLeft: "10px" }}>
                    <Stepper
                      activeStep={activeStep}
                      orientation="vertical"
                      sx={{ height: "100%" }}
                    >
                      {steps.map((step, index) => (
                        <Step key={step.label}>
                          <StepLabel>{step.label}</StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </div>
                </div>
              </Box>
              <DialogContent
                sx={{
                  width: "60%",
                  padding: "45px 45px 85px 45px",
                  border: "0.5px solid #EAEAEA",
                  borderRadius: "5px",
                  // marginTop:"50px",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                  height: "555px",
                }}
              >
                {React.cloneElement(steps[activeStep].detailComponent, {
                  onChange: handleDetailChange,
                  selectedPageSize,
                })}
                {activeStep === steps.length - 1 && (
                  <>
                    <Button
                      style={{ width: "100%", margin: "auto" }}
                      onClick={handleCalculatePrice}
                    >
                      Calculate Total Price
                    </Button>
                    {
                      <p
                        style={{
                          width: "100%",
                          margin: "auto",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {" "}
                        Total Price: ${totalPrice ? totalPrice : 0}
                      </p>
                    }
                  </>
                )}
              </DialogContent>
            </div>
            <div
              style={{
                width: "60%",
                display: "flex",
                justifyContent: "space-evenly",
                alignSelf: "flex-end",
                // marginTop: "10px",
              }}
            >
              {activeStep !== 0 && (
                <Button onClick={handleBack} style={{ marginRight: "10px" }}>
                  <ChevronLeftIcon />
                  Back
                </Button>
              )}
              {activeStep !== steps.length - 1 && (
                <Button
                  style={{ width: "100%", margin: "auto" }}
                  onClick={() => {
                    handleNext();
                    // handleCalculatePrice();
                  }}
                >
                  Next
                  <ChevronRightIcon />
                </Button>
              )}
              {activeStep === steps.length - 1 && (
                <div
                  style={{
                    width: "60%",
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignSelf: "flex-end",
                  }}
                >
                  <Button onClick={handleCheckout}>Checkout</Button>
                </div>
              )}
            </div>
          </div>
          <ToastContainer />
        </div>
      </ThemeProvider>
    </>
  );
}
