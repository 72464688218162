import React from "react";
import "./iphone.css";
import parse from "html-react-parser";
function Iphone({ data, font }) {
  function removeEmptyPTags(content) {
    const doc = new DOMParser().parseFromString(content, "text/html");
    const pTags = doc.querySelectorAll("p");

    pTags.forEach((pTag) => {
      if (!pTag.textContent.trim()) {
        pTag.remove();
      } else {
        pTag.style.margin = "0";
      }
    });

    return doc.body.innerHTML;
  }

  const cleanedText = removeEmptyPTags(data);
  return (
    <div class='iphone'>
      <div
        class='screen'
        style={{ ...font(), whiteSpace: "pre-wrap", wordWrap: "break-word" }}
      >
        {parse(data)}
      </div>

      <div class='notch'></div>
    </div>
  );
}

export default Iphone;
