import { toast } from "react-toastify";
import AWS from "aws-sdk";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendEmailVerification,
  setPersistence,
  browserSessionPersistence,
} from "firebase/auth";
import "react-toastify/dist/ReactToastify.css";
import {
  FETCH_BOOK_REQUEST,
  FETCH_BOOK_SUCCESS,
  FETCH_BOOK_FAILURE,
  CREATE_BOOK_REQUEST,
  CREATE_BOOK_SUCCESS,
  CREATE_BOOK_FAILURE,
  FETCH_SINGLE_BOOK_REQUEST,
  FETCH_SINGLE_BOOK_SUCCESS,
  FETCH_SINGLE_BOOK_FAILURE,
  ADD_CHAPTER_REQUEST,
  ADD_CHAPTER_SUCCESS,
  ADD_CHAPTER_FAILURE,
  SAVE_DEDICATION_REQUEST,
  SAVE_DEDICATION_SUCCESS,
  SAVE_DEDICATION_FAILURE,
  SAVE_PREFACE_REQUEST,
  SAVE_PREFACE_SUCCESS,
  SAVE_PREFACE_FAILURE,
  SAVE_INTRODUCTION_REQUEST,
  SAVE_INTRODUCTION_SUCCESS,
  SAVE_INTRODUCTION_FAILURE,
  SAVE_FOREWORD_REQUEST,
  SAVE_FOREWORD_SUCCESS,
  SAVE_FOREWORD_FAILURE,
  DELETE_BOOK_REQUEST,
  DELETE_BOOK_FAILURE,
  DELETE_BOOK_SUCCESS,
  DELETE_CHAPTER_REQUEST,
  DELETE_CHAPTER_FAILURE,
  DELETE_CHAPTER_SUCCESS,
  SAVE_CHAPTER_REQUEST,
  SAVE_CHAPTER_FAILURE,
  SAVE_CHAPTER_SUCCESS,
  ADD_SUBCHAPTER_REQUEST,
  ADD_SUBCHAPTER_FAILURE,
  ADD_SUBCHAPTER_SUCCESS,
  SAVE_SUBCHAPTER_REQUEST,
  SAVE_SUBCHAPTER_FAILURE,
  SAVE_SUBCHAPTER_SUCCESS,
  DELETE_SUBCHAPTER_REQUEST,
  DELETE_SUBCHAPTER_FAILURE,
  DELETE_SUBCHAPTER_SUCCESS,
  SIGNUP_USER_REQUEST,
  SIGNUP_USER_FAILURE,
  SIGNUP_USER_SUCCESS,
  SIGNIN_USER_REQUEST,
  SIGNIN_USER_FAILURE,
  SIGNIN_USER_SUCCESS,
  // FORGET_PASSWORD_REQUEST,
  // FORGET_PASSWORD_SUCCESS,
  // FORGET_PASSWORD_FAILURE,
  // RESET_PASSWORD_REQUEST,
  // RESET_PASSWORD_SUCCESS,
  // RESET_PASSWORD_FAILURE,
  // VERIFY_EMAIL_REQUEST,
  // VERIFY_EMAIL_SUCCESS,
  // VERIFY_EMAIL_FAILURE,
  UPDATE_BOOK_STATUS_REQUEST,
  UPDATE_BOOK_STATUS_SUCCESS,
  UPDATE_BOOK_STATUS_FAILURE,
  GOOGLE_SIGNIN_REQUEST,
  GOOGLE_SIGNIN_SUCCESS,
  GOOGLE_SIGNIN_FAILURE,
  UPDATE_BOOK_REQUEST,
  UPDATE_BOOK_SUCCESS,
  UPDATE_BOOK_FAILURE,
  FETCH_ORDER_SUCCESS,
  FETCH_ORDER_FAILURE,
  UPLOAD_COVER_IMAGE_REQUEST,
  UPLOAD_COVER_IMAGE_SUCCESS,
  UPLOAD_COVER_IMAGE_FAILURE,
  UPLOAD_FRONT_COVER_IMAGE_REQUEST,
  UPLOAD_FRONT_COVER_IMAGE_SUCCESS,
  UPLOAD_FRONT_COVER_IMAGE_FAILURE,
  FETCH_PDF_REQUEST,
  FETCH_PDF_SUCCESS,
  FETCH_PDF_FAILURE,
  SET_SELECTED_PAGE_SIZE,
} from "../actiontype/actiontype";

import axios from "axios";

const provider = new GoogleAuthProvider();
provider.addScope("https://www.googleapis.com/auth/contacts.readonly");
const auth = getAuth();
export const selectTemplate = (template) => {
  return {
    type: "SELECT_TEMPLATE",
    payload: template,
  };
};
export const preview = () => ({
  type: "PREVIEW",
});

export const setCoverImage = (coverImage) => {
  return {
    type: "SET_COVER_IMAGE",
    payload: coverImage,
  };
};

export const addchapter = (payload) => ({
  type: "ADD_CHAPTER",
  payload,
});

export const changetext = (payload) => ({
  type: "CHANGE_TEXT_CHAPTER",
  payload,
});
export const changetextsub = (payload) => ({
  type: "CHANGE_TEXT_SUB_CHAPTER",
  payload,
});
export const changeChapterTitle = (payload) => ({
  type: "CHANGE_CHAPTER_TITLE",
  payload,
});

export const createBook = (payload) => ({
  type: "CREATE_NEW_BOOK",
  payload,
});

export const changeSubtitile = (payload) => {
  return {
    type: "CHANGE_SUBCHAPTER_TITLE",
    payload,
  };
};
export const clearState = () => ({
  type: "CLEAR_STATE",
});

// const URL = "http://localhost:3001/api";
// const URL = "https://fuzzy-bass-veil.cyclic.app";
const URL = "https://app.simplymemoirs.com/api";
// const token = localStorage.getItem("token");

/////// GETTING BOOK LIST//////////////////////////

export const fetchBookRequest = () => {
  return {
    type: FETCH_BOOK_REQUEST,
  };
};
export const fetchBookSuccess = (data) => {
  return {
    type: FETCH_BOOK_SUCCESS,
    payload: data,
  };
};

export const fetchBookFailure = (err) => {
  return {
    type: FETCH_BOOK_FAILURE,
    payload: err,
  };
};

export const fetchBookList = (token) => {
  return (dispatch) => {
    dispatch(fetchBookRequest());
    const configure = {
      headers: { Authorization: `Bearer ${token}` },
    };
    axios
      .get(`${URL}/books`, configure)
      .then((res) => {
        dispatch(fetchBookSuccess(res.data));
      })
      .catch((err) => {
        console.log(err.response.statusText, "err");
        dispatch(fetchBookFailure(err));
        // toast.error(err.response.statusText);
      });
  };
};
//////////// END ///////////////////////////////////////

//////////// CREATE NEW BOOK//////////////////////////

export const createBookRequest = () => {
  return {
    type: CREATE_BOOK_REQUEST,
  };
};

export const createBookSuccess = (data) => {
  return {
    type: CREATE_BOOK_SUCCESS,
    payload: data,
  };
};
export const createBookFail = (err) => {
  return {
    type: CREATE_BOOK_FAILURE,
    payload: err,
  };
};

// export const createNewBook = (data) => {
//   console.log(data, "data");

//   return (dispatch) => {
//     const token = localStorage.getItem("token");
//     const configure = {
//       headers: { Authorization: `Bearer ${token}` },
//     };
//     dispatch(createBookRequest());
//       try {
//       const response = await axios.post(
//         "http://localhost:3001/api/convert",
//         formData,
//         {
//           headers: { "Content-Type": "multipart/form-data" },
//         }
//       );

//       // Log the entire response to understand its structure
//       console.log("File upload response:", response);

//       // Log and check the type of response.data
//       console.log("Response data:", response.data);
//       console.log("Type of response.data:", typeof response.data);

//       // Assuming response.data is directly the HTML content
//       let htmlContent = response.data;

//       // Debug the content type
//       console.log("HTML Content:", htmlContent);
//       console.log("Type of HTML Content:", typeof htmlContent);
//     } catch (error) {
//       console.error("Error uploading file:", error);
//     }
//     axios
//       .post(`${URL}/createnewbook`, data, configure)
//       .then((res) => {
//         dispatch(createBookSuccess(res.data));
//         dispatch(addingNewChapter(res.data.response?._id, "Chapter 1", ""));
//         if (res) {
//           dispatch(fetchBookList());
//         }
//       })
//       .catch((err) => {
//         dispatch(createBookFail(err.message));
//       });
//   };
// };
///////////////// END ///////////////////////////////////////////////////////

///////////////// UPDATE BOOK //////////////////////////////////

// export const createNewBook = (data) => {
//   return async (dispatch) => {
//     const token = localStorage.getItem("token");
//     const configure = {
//       headers: { Authorization: `Bearer ${token}` },
//     };

//     dispatch(createBookRequest());

//     try {
//       let htmlContent = null;

//       // Check if data.file exists and run the convert API
//       if (data.file) {
//         const formData = new FormData();
//         formData.append("file", data.file);

//         try {
//           const response = await axios.post(
//             "http://localhost:3001/api/convert",
//             formData,
//             {
//               headers: { "Content-Type": "multipart/form-data" },
//             }
//           );

//           // Log and check the response
//           console.log("File upload response:", response);

//           // Assuming response.data contains the HTML content
//           htmlContent = response.data;

//           // Debug the HTML content
//           console.log("HTML Content:", htmlContent);
//         } catch (error) {
//           console.error("Error uploading file:", error);
//           throw new Error("File upload failed"); // Throw an error if conversion fails
//         }
//       }

//       // Proceed with creating the book
//       const res = await axios.post(`${URL}/createnewbook`, data, configure);

//       dispatch(createBookSuccess(res.data));
//       const bookId = res.data.response?._id;

//       if (htmlContent) {
//         const maxChunkSize = 5000; // Adjust the chunk size as per your requirement
//         let startIndex = 0;
//         let chapterNumber = 1;

//         while (startIndex < htmlContent.length) {
//           const chapterContent = htmlContent.slice(
//             startIndex,
//             startIndex + maxChunkSize
//           );
//           dispatch(
//             addingNewChapter(bookId, `Chapter ${chapterNumber}`, chapterContent)
//           );
//           startIndex += maxChunkSize;
//           chapterNumber++;
//         }

//         dispatch(
//           addingNewChapter(bookId, `Chapter ${chapterNumber}`, htmlContent)
//         );
//       } else {
//         // If no HTML content, create an empty chapter
//         dispatch(addingNewChapter(bookId, "Chapter 1", ""));
//       }

//       // dispatch(fetchBookList());
//     } catch (err) {
//       console.error("Error creating new book:", err);
//       dispatch(createBookFail(err.message));
//     }
//   };
// };

export const createNewBook = (data) => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");
    const configure = {
      headers: { Authorization: `Bearer ${token}` },
    };

    dispatch(createBookRequest());

    try {
      let htmlContent = null;

      // Check if data.file exists and run the convert API
      if (data.file) {
        const formData = new FormData();
        formData.append("file", data.file);

        try {
          const response = await axios.post(`${URL}/convert`, formData, {
            headers: { "Content-Type": "multipart/form-data" },
          });

          // Log and check the response
          console.log("File upload response:", response);

          // Assuming response.data contains the HTML content
          htmlContent = response.data;

          // Debug the HTML content
          console.log("HTML Content:", htmlContent);
        } catch (error) {
          console.error("Error uploading file:", error);
          throw new Error("File upload failed"); // Throw an error if conversion fails
        }
      }

      // Proceed with creating the book
      const res = await axios.post(`${URL}/createnewbook`, data, configure);

      dispatch(createBookSuccess(res.data));
      const bookId = res.data.response?._id;

      if (htmlContent) {
        const maxChunkSize = 25000; // Adjust the chunk size as per your requirement
        let startIndex = 0;
        let chapterNumber = 1;

        while (startIndex < htmlContent.length) {
          // Find the end of the nearest closing tag before maxChunkSize
          let endIndex = startIndex + maxChunkSize;

          if (endIndex < htmlContent.length) {
            const closingTagIndex = htmlContent.lastIndexOf("</", endIndex);

            // Ensure the closing tag is within the maxChunkSize limit
            if (closingTagIndex > startIndex) {
              endIndex =
                closingTagIndex +
                htmlContent.slice(closingTagIndex).indexOf(">") +
                1;
            }
          }

          const chapterContent = htmlContent.slice(startIndex, endIndex);
          dispatch(
            addingNewChapter(bookId, `Chapter ${chapterNumber}`, chapterContent)
          );
          startIndex = endIndex;
          chapterNumber++;
        }

        // If there is any remaining content after the last chunk
        if (startIndex < htmlContent.length) {
          dispatch(
            addingNewChapter(
              bookId,
              `Chapter ${chapterNumber}`,
              htmlContent.slice(startIndex)
            )
          );
        }
      } else {
        // If no HTML content, create an empty chapter
        dispatch(addingNewChapter(bookId, "Chapter 1", ""));
      }
    } catch (err) {
      console.error("Error creating new book:", err);
      dispatch(createBookFail(err.message));
    }
  };
};

export const updateBookRequest = () => ({
  type: UPDATE_BOOK_REQUEST,
});

export const updateBookSuccess = (data) => ({
  type: UPDATE_BOOK_SUCCESS,
  payload: data,
});

export const updateBookFail = (err) => ({
  type: UPDATE_BOOK_FAILURE,
  payload: err,
});

export const updateBook = (bookId, updatedData) => {
  return (dispatch) => {
    const token = localStorage.getItem("token");
    const configure = {
      headers: { Authorization: `Bearer ${token}` },
    };
    dispatch(updateBookRequest());
    axios
      .put(`${URL}/updatebook`, { bookId, ...updatedData }, configure)
      .then((res) => {
        dispatch(updateBookSuccess(res.data));
      })
      .catch((err) => {
        dispatch(updateBookFail(err.message));
      });
  };
};

///////////////// END ///////////////////////////////////

///////////////// FETCH SINGLE BOOK CHAPTER///////////////////////////////////////////////////////

export const fetchSingleBookRequest = () => {
  return {
    type: FETCH_SINGLE_BOOK_REQUEST,
  };
};

export const fetchSingleBookFailure = (err) => {
  return {
    type: FETCH_SINGLE_BOOK_FAILURE,
    payload: err,
  };
};

export const fetchSingleBookSuccess = (data) => {
  return {
    type: FETCH_SINGLE_BOOK_SUCCESS,
    payload: data,
  };
};

export const getSingleBookChapter = (id) => {
  return (dispatch) => {
    const token = localStorage.getItem("token");
    const configure = {
      headers: { Authorization: `Bearer ${token}` },
    };
    if (token) {
      axios
        .get(`${URL}/getchapters/?id=${id}`, configure)
        .then((res) => {
          dispatch(fetchSingleBookSuccess(res.data.book));
        })
        .catch((err) => dispatch(fetchSingleBookFailure(err.message)));
    } else {
      window.alert("something went wrong");
    }
  };
};
////////////////// END /////////////////////////////////////////////////////////////////

/////////////////////////// ADD CHAPTER////////////////////////////////////////////////////////////////
export const addChapterRequest = () => {
  return {
    type: ADD_CHAPTER_REQUEST,
  };
};
export const addChapterFail = (err) => {
  return {
    type: ADD_CHAPTER_FAILURE,
    payload: err,
  };
};

export const addChapterSuccess = () => {
  return {
    type: ADD_CHAPTER_SUCCESS,
  };
};

export const addingNewChapter = (id, chapterName, chapterBody) => {
  const body = {
    _id: id,
    chaptername: chapterName,
    chapterbody: chapterBody,
  };
  return (dispatch) => {
    dispatch(addChapterRequest());
    const token = localStorage.getItem("token");
    const configure = {
      headers: { Authorization: `Bearer ${token}` },
    };
    axios
      .post(`${URL}/addchapter`, body, configure)
      .then((res) => {
        dispatch(addChapterSuccess());
        dispatch(getSingleBookChapter(id));
      })
      .catch((err) => {
        dispatch(addChapterFail(err.message));
      });
  };
};

/////////////////////////// DELETE BOOK /////////////////////////

export const deleteBookRequest = () => {
  return {
    type: DELETE_BOOK_REQUEST,
  };
};

export const deleteBookFail = (err) => {
  return {
    type: DELETE_BOOK_FAILURE,
    payload: err,
  };
};

export const deleteBookSuccess = () => {
  return {
    type: DELETE_BOOK_SUCCESS,
  };
};

export const deleteBook = (id) => {
  return (dispatch) => {
    dispatch(deleteBookRequest());
    const token = localStorage.getItem("token");
    const configure = {
      headers: { Authorization: `Bearer ${token}` },
    };

    axios
      .delete(`${URL}/deletebook/${id}`, configure)
      .then((res) => {
        dispatch(deleteBookSuccess());
        if (res) {
          dispatch(fetchBookList(token));
        }
      })
      .catch((err) => {
        dispatch(deleteBookFail(err.data));
      });
  };
};
/////////////////////// END /////////////////////////////////////////////////////////////////

/////////////////// DELETE CHAPTER //////////////////////////////////

export const deleteChapterRequest = () => {
  return {
    type: DELETE_CHAPTER_REQUEST,
  };
};

export const deleteChapterFail = (err) => {
  return {
    type: DELETE_CHAPTER_FAILURE,
    payload: err,
  };
};

export const deleteChapterSuccess = (data) => {
  return {
    type: DELETE_CHAPTER_SUCCESS,
    payload: data,
  };
};

export const deleteChapter = (bookId, chapterId) => {
  return (dispatch) => {
    dispatch(deleteChapterRequest());
    const token = localStorage.getItem("token");
    const configure = {
      headers: { Authorization: `Bearer ${token}` },
    };

    axios
      .delete(`${URL}/books/${bookId}/chapters/${chapterId}`, configure)
      .then((res) => {
        if (res) {
          dispatch(getSingleBookChapter(bookId));
        }
      })
      .catch((err) => {
        dispatch(deleteChapterFail(err.message));
      });
  };
};

//////////////// CHAPTER SAVE //////////////////////////////////

export const saveChapterRequest = () => {
  return {
    type: SAVE_CHAPTER_REQUEST,
  };
};

export const saveChapterFail = (error) => {
  return {
    type: SAVE_CHAPTER_FAILURE,
    payload: error,
  };
};

export const saveChapterSuccess = () => {
  return {
    type: SAVE_CHAPTER_SUCCESS,
  };
};

export const saveChapter = (bookId, chapterId, updatedChapterName) => {
  return (dispatch) => {
    // Check if localStorage has the required HTML content
    const updatedChapterBody = localStorage.getItem("savedHtmlContent");

    if (!updatedChapterBody) {
      console.error("No HTML content found in local storage.");
      dispatch(saveChapterFail("No HTML content found in local storage."));
      return; // Exit early to prevent further action
    }

    console.log("Retrieved HTML Content:", updatedChapterBody);
    console.log("Type of Retrieved HTML Content:", typeof updatedChapterBody);

    const body = {
      bookId,
      chapterId,
      updatedChapterName,
      updatedChapterBody,
    };

    dispatch(saveChapterRequest());

    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No authorization token found.");
      dispatch(saveChapterFail("No authorization token found."));
      return;
    }

    const configure = {
      headers: { Authorization: `Bearer ${token}` },
    };

    axios
      .post(`${URL}/updatechapter`, body, configure)
      .then((res) => {
        dispatch(saveChapterSuccess());
      })
      .catch((err) => {
        console.error("Error saving chapter:", err.message);
        dispatch(saveChapterFail(err.message));
      });
  };
};
// export const saveChapter = (
//   bookId,
//   chapterId,
//   updatedChapterName
// ) => {
//   const updatedChapterBody = localStorage.getItem('savedHtmlContent');

//   if (!updatedChapterBody) {
//     console.error('No HTML content found in local storage.');
//     return; // Optionally handle the missing content
//   }

//   console.log('Retrieved HTML Content:', updatedChapterBody);
//   console.log('Type of Retrieved HTML Content:', typeof updatedChapterBody);

//   const body = {
//     bookId,
//     chapterId,
//     updatedChapterName,
//     updatedChapterBody,
//   };

//   return (dispatch) => {
//     dispatch(saveChapterRequest());
//     const token = localStorage.getItem('token');
//     const configure = {
//       headers: { Authorization: `Bearer ${token}` },
//     };

//     axios
//       .post(`${URL}/updatechapter`, body, configure)
//       .then((res) => {
//         dispatch(saveChapterSuccess());
//       })
//       .catch((err) => dispatch(saveChapterFail(err.message)));
//   };
// };

export const saveDedicationRequest = () => {
  return {
    type: SAVE_DEDICATION_REQUEST,
  };
};

export const saveDedicationFail = (error) => {
  return {
    type: SAVE_DEDICATION_FAILURE,
    payload: error,
  };
};

export const saveDedicationSuccess = () => {
  return {
    type: SAVE_DEDICATION_SUCCESS,
  };
};

export const saveDedication = (
  bookId,
  updatedDedicationName,
  updatedDedicationBody
) => {
  const body = {
    bookId,
    updatedDedicationName,
    updatedDedicationBody,
  };
  return (dispatch) => {
    dispatch(saveDedicationRequest());
    const token = localStorage.getItem("token");
    const configure = {
      headers: { Authorization: `Bearer ${token}` },
    };
    axios
      .post(`${URL}/updatededication`, body, configure)
      .then((res) => {
        console.log(body);
        dispatch(saveDedicationSuccess());
        dispatch(fetchBookList(token));
      })
      .catch((err) => dispatch(saveDedicationFail(err.message)));
  };
};
//////////////// PREFACE SAVE //////////////////////////////////

export const savePrefaceRequest = () => {
  return {
    type: SAVE_PREFACE_REQUEST,
  };
};

export const savePrefaceFail = (error) => {
  return {
    type: SAVE_PREFACE_FAILURE,
    payload: error,
  };
};

export const savePrefaceSuccess = () => {
  return {
    type: SAVE_PREFACE_SUCCESS,
  };
};

export const savePreface = (bookId, updatedPrefaceName, updatedPrefaceBody) => {
  const body = {
    bookId,
    updatedPrefaceName,
    updatedPrefaceBody,
  };
  return (dispatch) => {
    dispatch(savePrefaceRequest());
    const token = localStorage.getItem("token");
    const configure = {
      headers: { Authorization: `Bearer ${token}` },
    };
    axios
      .post(`${URL}/updatepreface`, body, configure)
      .then((res) => {
        console.log(body);
        dispatch(savePrefaceSuccess());
        dispatch(fetchBookList(token));
      })
      .catch((err) => dispatch(savePrefaceFail(err.message)));
  };
};
//////////////// INTRODUCTION SAVE //////////////////////////////////

export const saveIntroductionRequest = () => {
  return {
    type: SAVE_INTRODUCTION_REQUEST,
  };
};

export const saveIntroductionFail = (error) => {
  return {
    type: SAVE_INTRODUCTION_FAILURE,
    payload: error,
  };
};

export const saveIntroductionSuccess = () => {
  return {
    type: SAVE_INTRODUCTION_SUCCESS,
  };
};

export const saveIntroduction = (
  bookId,
  updatedIntroductionName,
  updatedIntroductionBody
) => {
  const body = {
    bookId,
    updatedIntroductionName,
    updatedIntroductionBody,
  };
  return (dispatch) => {
    dispatch(saveIntroductionRequest());
    const token = localStorage.getItem("token");
    const configure = {
      headers: { Authorization: `Bearer ${token}` },
    };
    axios
      .post(`${URL}/updateintroduction`, body, configure)
      .then((res) => {
        console.log(body);
        dispatch(saveIntroductionSuccess());
        dispatch(fetchBookList(token));
      })
      .catch((err) => dispatch(saveIntroductionFail(err.message)));
  };
};
//////////////// FOREWORD SAVE //////////////////////////////////

export const saveForewordRequest = () => {
  return {
    type: SAVE_FOREWORD_REQUEST,
  };
};

export const saveForewordFail = (error) => {
  return {
    type: SAVE_FOREWORD_FAILURE,
    payload: error,
  };
};

export const saveForewordSuccess = () => {
  return {
    type: SAVE_FOREWORD_SUCCESS,
  };
};

export const saveForeword = (
  bookId,
  updatedForewordName,
  updatedForewordBody
) => {
  const body = {
    bookId,
    updatedForewordName,
    updatedForewordBody,
  };
  return (dispatch) => {
    dispatch(saveForewordRequest());
    const token = localStorage.getItem("token");
    const configure = {
      headers: { Authorization: `Bearer ${token}` },
    };
    axios
      .post(`${URL}/updateforeword`, body, configure)
      .then((res) => {
        console.log(body);
        dispatch(saveForewordSuccess());
        dispatch(fetchBookList(token));
      })
      .catch((err) => dispatch(saveForewordFail(err.message)));
  };
};
/////////////// ADD SUBCHAPTER ////////////////

export const subChapterRequest = () => {
  return {
    type: ADD_SUBCHAPTER_REQUEST,
  };
};

export const addSubChapterFail = (err) => {
  return {
    type: ADD_SUBCHAPTER_FAILURE,
    payload: err,
  };
};

export const addSubChapterSuccess = (data) => {
  return {
    type: ADD_SUBCHAPTER_SUCCESS,
    payload: data,
  };
};

export const addSubChapter = (
  chapterId,
  subchaptertitle,
  subchapterbody,
  bookId
) => {
  return (dispatch) => {
    dispatch(subChapterRequest());
    const body = {
      chapterId,
      subchaptertitle,
      subchapterbody,
    };
    const token = localStorage.getItem("token");
    const configure = {
      headers: { Authorization: `Bearer ${token}` },
    };
    axios
      .post(`${URL}/addsubchapter`, body, configure)
      // https://app.simplymemoirs.com/api/addsubchapter
      .then((res) => {
        dispatch(getSingleBookChapter(bookId));
        dispatch(addSubChapterSuccess(res.data));
      })
      .catch((err) => dispatch(addSubChapterFail(err.message)));
  };
};
//////////////// END //////////////////////////

///////////////////// SAVE SUBCHAPTER ///////////////////////////////

export const saveSubchapterRequest = () => {
  return {
    type: SAVE_SUBCHAPTER_REQUEST,
  };
};

export const saveSubchapterFail = (err) => {
  return {
    type: SAVE_SUBCHAPTER_FAILURE,
    payload: err,
  };
};

export const saveSubchapterSuccess = () => {
  return {
    type: SAVE_SUBCHAPTER_SUCCESS,
  };
};

export const saveSubchapter = (
  bookId,
  chapterId,
  subchapterId,
  updatedSubChapterTitle,
  updatedSubChapterBody
) => {
  return (dispatch) => {
    dispatch(saveSubchapterRequest());
    const body = {
      bookId,
      chapterId,
      subchapterId,
      updatedSubChapterTitle,
      updatedSubChapterBody,
    };
    const token = localStorage.getItem("token");
    const configure = {
      headers: { Authorization: `Bearer ${token}` },
    };
    axios
      .post(`${URL}/updatesubchapter`, body, configure)
      .then((res) => {
        if (res) dispatch(saveSubchapterSuccess());
      })
      .catch((err) => dispatch(saveSubchapterFail(err.message)));
  };
};

//////////////// END ///////////////////////////////////////////////

//////////////////////////// DELETE SUBCHAPTER//////////////////////////

export const deleteSubchapterRequest = () => {
  return {
    type: DELETE_SUBCHAPTER_REQUEST,
  };
};

export const deleteSubchapterFail = (err) => {
  return {
    type: DELETE_SUBCHAPTER_FAILURE,
    error: err,
  };
};

export const deleteSubchapterSuccess = (data) => {
  return {
    type: DELETE_SUBCHAPTER_SUCCESS,
    payload: data,
  };
};

export const deleteSubchapter = (bookId, subChapterid) => {
  return (dispatch) => {
    dispatch(deleteSubchapterRequest());
    const token = localStorage.getItem("token");
    const configure = {
      headers: { Authorization: `Bearer ${token}` },
    };
    axios
      .delete(`${URL}/books/${bookId}/subchapters/${subChapterid}`, configure)
      .then((res) => {
        dispatch(deleteSubchapterSuccess(res.data));
      })
      .catch((err) => dispatch(deleteSubchapterFail(err.message)));
  };
};
//////////////////////////// END DELETE SUBCHAPTER//////////////////////////

//////////////////////////// SIGNUP USER//////////////////////////
export const signupUserRequest = () => {
  return {
    type: SIGNUP_USER_REQUEST,
  };
};

export const signupUserFail = (err) => {
  return {
    type: SIGNUP_USER_FAILURE,
    payload: err,
  };
};

export const signupUserSuccess = (data) => {
  return {
    type: SIGNUP_USER_SUCCESS,
    payload: data,
  };
};

export const signUp = (data) => {
  return (dispatch) => {
    dispatch(signupUserRequest());
    console.log(data, "data");
    const { email, password, firstName, lastName } = data;

    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;

        // Send email verification
        sendEmailVerification(user)
          .then(() => {
            // Email sent.
            dispatch(signupUserSuccess(user));
          })
          .catch((error) => {
            // Handle error
            console.error("Error sending verification email:", error.message);
            toast.error(error.message);
            dispatch(signupUserFail(error.message));
          });
      })
      .catch((error) => {
        // Handle sign-up error
        console.error("Error signing up user:", error.message);

        // Check if the error is related to email duplication
        if (error.code) {
          if (error.code === "auth/email-already-in-use") {
            const errorMessage =
              "Email is already in use. Please use a different email.";
            toast.error(errorMessage);
            dispatch(signupUserFail(errorMessage));
          } else {
            const errorMessage = `Signup failed: ${error.message}`;
            toast.error(errorMessage);
            dispatch(signupUserFail(errorMessage));
          }
        } else {
          const errorMessage = "An unknown error occurred during signup.";
          toast.error(errorMessage);
          dispatch(signupUserFail(errorMessage));
        }
      });
  };
};

/////////////////////// END SIGNUP ////////////////////////////////////////////////////

///////////////////////  SIGNIN ////////////////////////////////////////////////////

export const signinUserRequest = () => {
  return {
    type: SIGNIN_USER_REQUEST,
  };
};

export const signinUserFail = (err) => {
  return {
    type: SIGNIN_USER_FAILURE,
    payload: err,
  };
};

export const signinUserSuccess = (data) => {
  return {
    type: SIGNIN_USER_SUCCESS,
    payload: data,
  };
};

export const signInUser = (data) => {
  return (dispatch) => {
    dispatch(signinUserRequest());

    const { email, password } = data;

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        const token = user.accessToken;

        user.getIdToken().then((token) => {
          localStorage.setItem("token", token);
        });
        dispatch(signinUserSuccess(user, token));
      })
      .catch((error) => {
        if (
          error.code === "auth/user-not-found" ||
          error.code === "auth/wrong-password"
        ) {
          dispatch(
            signinUserFail("Invalid email or password. Please try again.")
          );
          toast.error("Invalid email or password. Please try again.");
        } else if (error.code === "auth/unverified-email") {
          dispatch(
            signinUserFail("Email not verified. Please verify your email.")
          );
          toast.warning("Email not verified. Please verify your email.");
        } else {
          console.error("Other error message:", error.message);
          dispatch(signinUserFail("An error occurred. Please try again."));
          toast.error(error.message);
        }
      });
  };
};

export const googleSigninRequest = () => ({
  type: GOOGLE_SIGNIN_REQUEST,
});

export const googleSigninSuccess = (user) => ({
  type: GOOGLE_SIGNIN_SUCCESS,
  payload: user,
});

export const googleSigninFailure = (error) => ({
  type: GOOGLE_SIGNIN_FAILURE,
  payload: error,
});

export const signinWithGoogle = () => {
  return (dispatch) => {
    dispatch(googleSigninRequest());
    const provider = new GoogleAuthProvider();
    setPersistence(auth, browserSessionPersistence)
      .then(() => {
        // Now signInWithPopup with the configured persistence
        return signInWithPopup(auth, provider);
      })
      .then((result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        const user = result.user;

        if (user) {
          user.getIdToken().then((tkn) => {
            localStorage.setItem("token", tkn);
            // console.log(tkn);
          });
          dispatch(googleSigninSuccess(user, token));
          // console.log(user, "hello");
        }
      })
      .catch((error) => {
        dispatch(googleSigninFailure(error.message));
      });
  };
};

/////////////////////// END SIGNIN ////////////////////////////////////////////////////

const updateBookStatusRequest = () => ({
  type: UPDATE_BOOK_STATUS_REQUEST,
});

const updateBookStatusSuccess = (response) => ({
  type: UPDATE_BOOK_STATUS_SUCCESS,
  response,
});

const updateBookStatusFailure = (error) => ({
  type: UPDATE_BOOK_STATUS_FAILURE,
  error,
});

export const updateBookStatus = (bookId, newStatus) => {
  return (dispatch) => {
    dispatch(updateBookStatusRequest());
    const authToken = localStorage.getItem("token");
    axios
      .patch(
        `${URL}/status/${bookId}`,
        { status: newStatus },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      .then((response) => {
        dispatch(updateBookStatusSuccess(response.data));
      })
      .catch((error) => {
        dispatch(updateBookStatusFailure(error));
        // Handle error
        console.error("Error updating order status:", error);
      });
  };
};

// get order for editor disabling//

const fetchOrderSuccess = (data) => ({
  type: FETCH_ORDER_SUCCESS,
  payload: data,
});

const fetchOrderFailure = (error) => ({
  type: FETCH_ORDER_FAILURE,
  payload: error,
});
export const fetchOrder = (id) => {
  return (dispatch) => {
    const authToken = localStorage.getItem("token");
    axios
      .get(`${URL}/getorderforeditor/${id}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((response) => {
        dispatch(fetchOrderSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchOrderFailure(error));
      });
  };
};

// Upload Cover Image //

AWS.config.update({
  accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
  region: "us-west-1",
});

const s3 = new AWS.S3();
export const uploadBookCover = (bookId, file, token) => async (dispatch) => {
  dispatch({ type: UPLOAD_COVER_IMAGE_REQUEST });

  const params = {
    Bucket: "simplymemoirs",
    Key: `images/${file.name}`,
    Body: file,
    ACL: "public-read",
  };

  s3.upload(params, async (err, data) => {
    if (err) {
      dispatch({ type: UPLOAD_COVER_IMAGE_FAILURE, payload: err.message });
      return;
    }

    try {
      const response = await axios.put(
        `${URL}/bookcover/${bookId}`,
        { bookCover: data.Location },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch({ type: UPLOAD_COVER_IMAGE_SUCCESS, payload: data.Location });
      console.log("Successfully updated book cover URL:", response.data);
    } catch (error) {
      dispatch({ type: "UPLOAD_BOOK_COVER_FAILURE", payload: error.message });
    }
  });
};

////////FRONT COVER///////
export const uploadFrontBookCover =
  (bookId, file, token) => async (dispatch) => {
    dispatch({ type: UPLOAD_FRONT_COVER_IMAGE_REQUEST });

    const params = {
      Bucket: "simplymemoirs",
      Key: `images/${file.name}`,
      Body: file,
      ACL: "public-read",
    };

    s3.upload(params, async (err, data) => {
      if (err) {
        dispatch({
          type: UPLOAD_FRONT_COVER_IMAGE_FAILURE,
          payload: err.message,
        });
        return;
      }

      try {
        const response = await axios.put(
          `${URL}/frontbookcover/${bookId}`,
          { bookFrontCover: data.Location },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        dispatch({
          type: UPLOAD_FRONT_COVER_IMAGE_SUCCESS,
          payload: data.Location,
        });
        console.log(
          "Successfully updated book front cover URL:",
          response.data
        );
      } catch (error) {
        dispatch({ type: "UPLOAD_BOOK_COVER_FAILURE", payload: error.message });
      }
    });
  };
///////////Page Size////////////
// In your Redux slice file or action file (e.g., actions.js)

export const setSelectedPageSize = (pageSize) => ({
  type: SET_SELECTED_PAGE_SIZE,
  payload: pageSize,
});

///////////////PDF///////////////

const fetchPdfRequest = () => ({
  type: FETCH_PDF_REQUEST,
});

const fetchPdfSuccess = (pdfData) => ({
  type: FETCH_PDF_SUCCESS,
  payload: pdfData,
});

const fetchPdfFailure = (error) => ({
  type: FETCH_PDF_FAILURE,
  payload: error,
});

export const fetchPdf =
  (id, selectedPageSize) => async (dispatch, getState) => {
    console.log("Fetching PDF with id:", id, "and pageSize:", selectedPageSize);
    dispatch(fetchPdfRequest());
    const token = localStorage.getItem("token");
    const configure = {
      headers: { Authorization: `Bearer ${token}` },
    };

    try {
      const response = await axios.get(
        `${URL}/pdf/${id}?width=${getWidth(
          selectedPageSize
        )}&height=${getHeight(selectedPageSize)}`,
        configure
      );
      dispatch(fetchPdfSuccess(response.data));
      dispatch(fetchBookList(token));
      console.log("API Response:", response);
    } catch (error) {
      dispatch(fetchPdfFailure(error.message));
    }
  };

function getWidth(pageSize) {
  switch (pageSize) {
    case "A4":
      return "8.27in";
    case "8.5 x 11":
      return "8.5in";
    case "8 x 10":
      return "8in";
    case "6 x 9":
      return "6in";
    case "11 x 8.5":
      return "11in";
    case "10 x 8":
      return "10in";
    case "9 x 6":
      return "9in";
    default:
      return "8.27in";
  }
}
// getting width according to different cases in array of device list
function getHeight(pageSize) {
  switch (pageSize) {
    case "A4":
      return "11.69in";
    case "8.5 x 11":
      return "11in";
    case "8 x 10":
      return "10in";
    case "6 x 9":
      return "9in";
    case "11 x 8.5":
      return "8.5in";
    case "10 x 8":
      return "8in";
    case "9 x 6":
      return "6in";
    default:
      return "11.69in";
  }
}
