import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import "./payment.css";

export default function Paymentsuccess() {
  const navigate=useNavigate();
  const [countdown, setCountdown] = useState(10);

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown(prevCountdown => prevCountdown - 1);
    }, 1000);
    return () => clearInterval(timer);
  }, []);
  useEffect(() => {
    if (countdown === 0) {
      navigate('/orderlist');
    }
  }, [countdown, navigate]);

  return (
    <div className="card123">
      <div className="circle">
        <i className="checkmark">✓</i>
      </div>
      <h1 className='hey'>Order Confirmed</h1>
      {countdown > 0 && (
        <p style={{fontSize:"14px"}}>You will be redirected to your Orders in <b>{countdown}</b> seconds</p>
      )}
      {countdown === 0 && (
        <p>Redirecting...</p>
      )}
    </div>
  );
}
