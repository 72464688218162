import React from "react";
import ReactQuill from "react-quill";
import { modules } from "../utils/helper";
export default function Copyright() {
  let text = `
      <p
        className='col-11'
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          backgroundColor: "#1E1E1E",
          margin: "0 auto",
          padding: "8px",
          paddingLeft: "12px",
          borderRadius: "5px",
        }}
      >
        <h3>Copyright</h3>
        <h1>Copyright</h1>
      </p>
      <p className='col-10' style={{ padding: "20px", margin: "20px auto" }}>
        <h3>Copyright © 2023 by Simply Memoirs.</h3>
        <br />
        <h5>All rights reserved.</h5>
        <br />
        <p>
          No portion of this book may be reproduced in any form without written
          permission from the publisher or author, except as permitted by U.S.
          copyright law.
        </p>
      </p>
    `;
  return (
    <div>
    <div className='row'>
      <div
        className={"col-12"}
        style={{ position: "relative" }}
      >
        <div
          className='editor-container col-12'
          style={{
            borderRadius: "20px 20px 0px 0px",
            borderLeft: "2px solid #503981",
            borderRight: "2px solid #503981",
            borderTop: "2px solid #503981",
          }}
        >
          <div className={"col-10"}>
            <h3 style={{ marginTop: "10px",display:"flex"}}>
                <input
                  type='text'
                  value='Copyright'
                  disabled={true}
                />
            </h3>
          </div>
        </div>
        <ReactQuill
          theme='snow'
          modules={modules}
          value={text}
        />
      </div>
    </div>
  </div>
  );
}
