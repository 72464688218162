import React from "react";
import parse from "html-react-parser";
import "./ipadpro.css";
function IpadPro({ data, font }) {
  console.log(data);
  return (
    <div>
      <div className='container-div'>
        <div
          className='content'
          style={{
            padding: "20px",
            whiteSpace: "pre-line",
            wordWrap: "break-word",
            ...font(),
          }}
        >
          {parse(data)}
        </div>
      </div>
    </div>
  );
}

export default IpadPro;
