import {
    SET_SELECTED_PAGE_SIZE
  } from '../actiontype/actiontype';

const initialState = {
    selectedPageSize: 'A4', // Initial state
  };
  
  const PageSizeReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_SELECTED_PAGE_SIZE:
        return {
          ...state,
          selectedPageSize: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default PageSizeReducer;
  