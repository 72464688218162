import { UPLOAD_FRONT_COVER_IMAGE_REQUEST, UPLOAD_FRONT_COVER_IMAGE_SUCCESS,UPLOAD_FRONT_COVER_IMAGE_FAILURE } from '../actiontype/actiontype';

const initialState = {
  coverUrl: null,
  loading: false,
  error: null,
};

const frontBookCoverReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPLOAD_FRONT_COVER_IMAGE_REQUEST:
      return { ...state, loading: true, error: null };
    case UPLOAD_FRONT_COVER_IMAGE_SUCCESS:
      return { ...state, loading: false, coverUrl: action.payload };
    case UPLOAD_FRONT_COVER_IMAGE_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default frontBookCoverReducer;
