import {
    UPDATE_BOOK_REQUEST,
    UPDATE_BOOK_SUCCESS,
    UPDATE_BOOK_FAILURE,
  } from "../actiontype/actiontype";
  
  const initialState = {
    loading: false,
    error: null,
    updatedBook: null,
  };
  
  const bookReducer = (state = initialState, action) => {
    switch (action.type) {
      case UPDATE_BOOK_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
          updatedBook: null,
        };
  
        case UPDATE_BOOK_SUCCESS:
            console.log("Update success:", action.payload);
            const updatedBooks = state.data.books.map((book) =>
                book._id === action.payload._id ? { ...book, ...action.payload } : book
            );
            return {
                ...state,
                loading: false,
                data: { ...state.data, books: updatedBooks },
            };

  
      case UPDATE_BOOK_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default bookReducer;
  