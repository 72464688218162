import React, { useState, useRef, useEffect } from "react";
import ReactQuill from "react-quill";
import { modules , formats} from "../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import { saveDedication } from "../redux/action/action";

import { useParams } from "react-router-dom";

export default function Dedication() {
  const [dedicationName, setDedicationName] = useState("Dedication");
  const [isTyping, setIsTyping] = useState(false);
  const dispatch = useDispatch();
  const {id} = useParams();
  const listBooks = useSelector((s) => s.reducer.data.books);
  const selectedBook = listBooks?.find((book) => book._id === id);
  const text = selectedBook.dedication?.body
  const [value, setValue] = useState(text);
  const savingDedication = () => {
    dispatch(saveDedication(id, dedicationName, value));
  };
  useEffect(() => {
    let timeoutId;
    if (isTyping) {
      timeoutId = setTimeout(() => {
        setIsTyping(false);
      }, 3000);
    } else {
        savingDedication();
    }
  
    return () => {
      clearTimeout(timeoutId);
    };
  }, [value, isTyping]);
  const handleChange = (html) => {
    setIsTyping(true);

    setValue(html);
  };
  return (
    <div>
    <div className='row'>
      <div
        className={"col-12"}
        style={{ position: "relative" }}
      >
        <div
          className='editor-container col-12'
          style={{
            borderRadius: "20px 20px 0px 0px",
            borderLeft: "2px solid #503981",
            borderRight: "2px solid #503981",
            borderTop: "2px solid #503981",
          }}
        >
          <div className={"col-10"}>
            <h3 style={{ marginTop: "10px",display:"flex"}}>
                <input
                  type='text'
                  value='Dedication'
                  disabled={true}
                />
            </h3>
          </div>
          <div
              className={ "icons-container col-2 align-items-center" }
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <div className='d-flex align-items-center'>
                {isTyping ? (
                  <p className='fade-in-out-text'>saving</p>
                ) : (
                  <p>saved</p>
                )}
              </div>

              <div
                data-title='SAVE'
                onClick={() => savingDedication()}
              >
                <svg
                  width={"50px"}
                  height={"50px"}
                  viewBox='0 0 34 34'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M7 13.6667C7 10.9958 7 9.65939 7.92783 8.83017C8.85461 8 10.3482 8 13.3333 8H20.0287C20.8922 8 21.3228 8 21.7113 8.14356C22.0987 8.28711 22.4037 8.561 23.0149 9.10689L24.7629 10.6709C25.3741 11.2168 25.6781 11.4907 25.8396 11.8373C26 12.1848 26 12.5702 26 13.3427V19.3333C26 22.0042 26 23.3406 25.0722 24.1698C24.3956 24.7762 23.4171 24.9396 21.7778 24.9839V22.1081C21.7778 21.4904 21.7778 20.9361 21.7102 20.4865C21.6363 19.9954 21.4653 19.4929 21.0051 19.0802C20.5438 18.6684 19.9812 18.5154 19.4334 18.4493C18.9309 18.3889 18.3113 18.3889 17.621 18.3889H14.3234C13.6331 18.3889 13.0135 18.3889 12.5111 18.4493C11.9622 18.5154 11.4006 18.6684 10.9393 19.0802C10.4791 19.4929 10.3081 19.9963 10.2342 20.4865C10.1667 20.9361 10.1667 21.4904 10.1667 22.1081V24.9282C9.1375 24.8376 8.44506 24.6326 7.92783 24.1698C7 23.3406 7 22.0042 7 19.3333V13.6667ZM19.6667 22.1667V25H13.3333C12.9586 25 12.6071 25 12.2778 24.9981V22.1667C12.2778 21.4725 12.2799 21.0466 12.3263 20.7377C12.3675 20.4676 12.4245 20.4223 12.4319 20.4166C12.4393 20.41 12.4889 20.3581 12.7918 20.3212C13.137 20.2797 13.6131 20.2778 14.3889 20.2778H17.5556C18.3314 20.2778 18.8074 20.2797 19.1526 20.3212C19.4556 20.3581 19.5052 20.4091 19.5115 20.4157H19.5126C19.5199 20.4223 19.5769 20.4676 19.6181 20.7377C19.6646 21.0466 19.6667 21.4725 19.6667 22.1667ZM11.2222 11.7778C10.9423 11.7778 10.6738 11.8773 10.4758 12.0544C10.2779 12.2315 10.1667 12.4717 10.1667 12.7222C10.1667 12.9727 10.2779 13.2129 10.4758 13.39C10.6738 13.5672 10.9423 13.6667 11.2222 13.6667H16.5C16.78 13.6667 17.0484 13.5672 17.2464 13.39C17.4443 13.2129 17.5556 12.9727 17.5556 12.7222C17.5556 12.4717 17.4443 12.2315 17.2464 12.0544C17.0484 11.8773 16.78 11.7778 16.5 11.7778H11.2222Z'
                    fill='#503981'
                  />
                </svg>
              </div>
            </div>
        </div>
        <ReactQuill
          theme='snow'
          modules={modules}
          onChange={handleChange}
          formats={formats}
          value={value}
        />
      </div>
    </div>
  </div>
  );
}
