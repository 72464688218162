import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import softcover from "../../images/softcover.png";
import hardcover from "../../images/hardcover.png";
import economy from "../../images/economy.png";
import deluxe from "../../images/deluxe.png";
import standard from "../../images/standard.png";
import premium from "../../images/premium.png";
import small from "../../images/small.png";
import medium from "../../images/medium.png";
import large from "../../images/large.png";
import portrait from "../../images/portrait.png";
import landscape from "../../images/landscape.png";
import PrintPreview from "../MobilePreview/PrintPreview";

export const BookTypeDetail = ({ onChange }) => {
  const [selectedDiv, setSelectedDiv] = useState(null);

  const handleDivClick = (divId, price) => {
    setSelectedDiv(divId);
    onChange("bookType", divId === 1 ? "Soft Cover" : "Hard Cover", price);
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "30px",
      }}
    >
      <h5 style={{ textAlign: "center" }}>Book Type Detail</h5>
      <div style={{ display: "flex" }}>
        <div
          onClick={() => handleDivClick(1, 20)}
          style={{
            border:
              selectedDiv === 1 ? "2px solid blue" : "2px solid transparent",
            cursor: "pointer",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: "10px",
            borderRadius: "10px",
          }}
        >
          <img
            src={softcover}
            alt="Image 1"
            style={{ width: "150px", height: "150px" }}
          />
          <p>Soft Cover</p>
          <p>Price: $20</p>
        </div>

        <div
          onClick={() => handleDivClick(2, 25)}
          style={{
            border:
              selectedDiv === 2 ? "2px solid blue" : "2px solid transparent",
            cursor: "pointer",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: "10px",
            borderRadius: "10px",
          }}
        >
          <img
            src={hardcover}
            alt="Image 2"
            style={{ width: "150px", height: "150px" }}
          />
          <p>Hard Cover</p>
          <p>Price: $25</p>
        </div>
      </div>
    </div>
  );
};

export const PrintQualityDetail = ({ onChange }) => {
  const [selectedDiv, setSelectedDiv] = useState(null);

  const handleDivClick = (divId, price) => {
    setSelectedDiv(divId);
    onChange("printQuality", divId === 1 ? "Economy" : "Deluxe", price);
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "30px",
      }}
    >
      <h5 style={{ textAlign: "center" }}>Print Quality Detail</h5>
      <div style={{ display: "flex" }}>
        <div
          onClick={() => handleDivClick(1, 5)}
          style={{
            border:
              selectedDiv === 1 ? "2px solid blue" : "2px solid transparent",
            cursor: "pointer",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: "10px",
            borderRadius: "10px",
          }}
        >
          <img
            src={economy}
            alt="Image 1"
            style={{ width: "180px", height: "150px" }}
          />
          <p>Economy</p>
          <p>Price: $5</p>
        </div>

        <div
          onClick={() => handleDivClick(2, 8)}
          style={{
            border:
              selectedDiv === 2 ? "2px solid blue" : "2px solid transparent",
            cursor: "pointer",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: "10px",
            borderRadius: "10px",
          }}
        >
          <img
            src={deluxe}
            alt="Image 2"
            style={{ width: "180px", height: "150px" }}
          />
          <p>Deluxe</p>
          <p>Price: $8</p>
        </div>
      </div>
    </div>
  );
};

export const OrientationDetail = ({ onChange }) => {
  const [selectedDiv, setSelectedDiv] = useState(null);

  const handleDivClick = (divId, price) => {
    setSelectedDiv(divId);
    onChange("orientation", divId === 1 ? "Portrait" : "Landscape", price);
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "30px",
      }}
    >
      <h5 style={{ textAlign: "center" }}>Orientation Detail</h5>
      <div style={{ display: "flex" }}>
        <div
          onClick={() => handleDivClick(1, 5)}
          style={{
            border:
              selectedDiv === 1 ? "2px solid blue" : "2px solid transparent",
            cursor: "pointer",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: "10px",
            borderRadius: "10px",
          }}
        >
          <img
            src={portrait}
            alt="Image 1"
            style={{ width: "100px", height: "150px" }}
          />
          <p>Portrait</p>
          <p>Price: $5</p>
        </div>

        <div
          onClick={() => handleDivClick(2, 5)}
          style={{
            border:
              selectedDiv === 2 ? "2px solid blue" : "2px solid transparent",
            cursor: "pointer",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            margin: "10px",
            borderRadius: "10px",
          }}
        >
          <img
            src={landscape}
            alt="Image 2"
            style={{ width: "150px", height: "100px", marginTop: "30px" }}
          />
          <p>Landscape</p>
          <p>Price: $5</p>
        </div>
      </div>
    </div>
  );
};

export const BookSizeDetail = ({ onChange }) => {
  const [selectedDiv, setSelectedDiv] = useState(null);

  const handleDivClick = (divId, price) => {
    setSelectedDiv(divId);
    let size = "";
    switch (divId) {
      case 1:
        size = "6x9";
        break;
      case 2:
        size = "8x10";
        break;
      case 3:
        size = "8.5x11";
        break;
      default:
        size = "";
    }
    onChange("bookSize", size, price);
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "30px",
        paddingBottom: "50px",
      }}
    >
      <h5 style={{ textAlign: "center" }}>Book Size Detail</h5>
      <div style={{ display: "flex" }}>
        <div
          onClick={() => handleDivClick(1, 20)}
          style={{
            border:
              selectedDiv === 1 ? "2px solid blue" : "2px solid transparent",
            cursor: "pointer",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: "10px",
            borderRadius: "10px",
          }}
        >
          <img
            src={small}
            alt="Image 1"
            style={{ width: "100px", height: "100px" }}
          />
          <p>6x9"</p>
          <p>Price: $20</p>
        </div>

        <div
          onClick={() => handleDivClick(2, 30)}
          style={{
            border:
              selectedDiv === 2 ? "2px solid blue" : "2px solid transparent",
            cursor: "pointer",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: "10px",
            borderRadius: "10px",
          }}
        >
          <img
            src={medium}
            alt="Image 2"
            style={{ width: "100px", height: "100px" }}
          />
          <p>8x10"</p>
          <p>Price: $30</p>
        </div>
        <div
          onClick={() => handleDivClick(3, 40)}
          style={{
            border:
              selectedDiv === 3 ? "2px solid blue" : "2px solid transparent",
            cursor: "pointer",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: "10px",
            borderRadius: "10px",
          }}
        >
          <img
            src={large}
            alt="Image 2"
            style={{ width: "100px", height: "100px" }}
          />
          <p>8.5x11"</p>
          <p>Price: $40</p>
        </div>
      </div>
    </div>
  );
};

export const PaperWeightDetail = ({ onChange }) => {
  const [selectedDiv, setSelectedDiv] = useState(null);

  const handleDivClick = (divId, price) => {
    setSelectedDiv(divId);
    onChange(
      "paperWeight",
      divId === 1 ? "80lb - 444 pages/inch" : "100lb - 376 pages/inch",
      price
    );
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "30px",
      }}
    >
      <h5 style={{ textAlign: "center" }}>Paper Weight Detail</h5>
      <div style={{ display: "flex" }}>
        <div
          onClick={() => handleDivClick(1, 4)}
          style={{
            border:
              selectedDiv === 1 ? "2px solid blue" : "2px solid transparent",
            cursor: "pointer",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: "10px",
            borderRadius: "10px",
          }}
        >
          <img src={standard} style={{ width: "150px", height: "150px" }} />
          <p style={{ textAlign: "center" }}>80lb - 444 pages/inch</p>
          <p>Price: $4</p>
        </div>

        <div
          onClick={() => handleDivClick(2, 5)}
          style={{
            border:
              selectedDiv === 2 ? "2px solid blue" : "2px solid transparent",
            cursor: "pointer",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: "10px",
            borderRadius: "10px",
          }}
        >
          <img
            src={premium}
            alt="Image 2"
            style={{ width: "150px", height: "150px" }}
          />
          <p style={{ textAlign: "center" }}>100lb - 376 pages/inch</p>
          <p>Price: $5</p>
        </div>
      </div>
    </div>
  );
};

export const DeliveryMethodDetail = ({ onChange }) => {
  const [selectedDiv, setSelectedDiv] = useState(null);

  const handleDivClick = (divId, price) => {
    setSelectedDiv(divId);
    onChange(
      "deliveryMethod",
      divId === 1 ? "Standard Delivery" : "Express Delivery",
      price
    );
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "30px",
      }}
    >
      <h5 style={{ textAlign: "center" }}>Delivery Detail</h5>
      <div style={{ display: "flex" }}>
        <div
          onClick={() => handleDivClick(1, 5)}
          style={{
            border:
              selectedDiv === 1 ? "2px solid blue" : "2px solid transparent",
            cursor: "pointer",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: "10px",
            borderRadius: "10px",
          }}
        >
          <img
            src={
              "https://simplymemoirs.s3.us-west-1.amazonaws.com/images/image.png-79"
            }
            alt="Image 1"
            style={{ width: "150px", height: "150px" }}
          />
          <p>Standard Delivery</p>
          <p>Price: $5</p>
        </div>

        <div
          onClick={() => handleDivClick(2, 15)}
          style={{
            border:
              selectedDiv === 2 ? "2px solid blue" : "2px solid transparent",
            cursor: "pointer",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: "10px",
            borderRadius: "10px",
          }}
        >
          <img
            src={
              "https://simplymemoirs.s3.us-west-1.amazonaws.com/images/image.png-86"
            }
            alt="Image 2"
            style={{ width: "150px", height: "150px" }}
          />
          <p>Express Delivery </p>
          <p>Price: $10</p>
        </div>
      </div>
    </div>
  );
};

export const CoverFinishDetail = ({ onChange }) => {
  const [selectedDiv, setSelectedDiv] = useState(null);
  const handleDivClick = (divId, price) => {
    setSelectedDiv(divId);
    onChange(
      "coverFinish",
      divId === 1 ? "Matte Finish" : "Gloss Finish",
      price
    );
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "30px",
      }}
    >
      <h5 style={{ textAlign: "center" }}>Cover Finish</h5>
      <div style={{ display: "flex" }}>
        <div
          onClick={() => handleDivClick(1, 5)}
          style={{
            border:
              selectedDiv === 1 ? "2px solid blue" : "2px solid transparent",
            cursor: "pointer",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: "10px",
            borderRadius: "10px",
          }}
        >
          <img
            src={
              "https://5.imimg.com/data5/VM/YG/SQ/SELLER-29267953/sand-mat-finish-book-binding-polypropylene-sheets-500x500.jpg"
            }
            alt="Image 1"
            style={{ width: "150px", height: "150px" }}
          />
          <p>Matte Finish</p>
          <p>Price: $5</p>
        </div>

        <div
          onClick={() => handleDivClick(2, 15)}
          style={{
            border:
              selectedDiv === 2 ? "2px solid blue" : "2px solid transparent",
            cursor: "pointer",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: "10px",
            borderRadius: "10px",
          }}
        >
          <img
            src={
              "https://cipher.dakiscdn.com/i/https://1410dd2bdd37e05ebf19-a2305060cc55ab8db83b1ef4b8835a6b.ssl.cf5.rackcdn.com/lcIgMGrbR803s85cwmuvUQ?w=400&h=400&p=1&a=1&q=display"
            }
            alt="Image 2"
            style={{ width: "150px", height: "150px" }}
          />
          <p>Gloss Finish</p>
          <p>Price: $10</p>
        </div>
      </div>
    </div>
  );
};

export const BookWeightDetail = ({ onChange }) => {
  const [selectedDiv, setSelectedDiv] = useState(null);
  const handleDivClick = (divId, price) => {
    setSelectedDiv(divId);
    let size = "";
    switch (divId) {
      case 1:
        size = "Small";
        break;
      case 2:
        size = "Medium";
        break;
      case 3:
        size = "Large";
        break;
      default:
        size = "";
    }
    onChange("bookWeight", size, price);
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "30px",
      }}
    >
      <h5 style={{ textAlign: "center" }}>Book Weight</h5>
      <div style={{ display: "flex" }}>
        <div
          onClick={() => handleDivClick(1, 0.7452)}
          style={{
            border:
              selectedDiv === 1 ? "2px solid blue" : "2px solid transparent",
            cursor: "pointer",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: "10px",
            borderRadius: "10px",
          }}
        >
          <img
            src={small}
            alt="Image 1"
            style={{ width: "100px", height: "100px" }}
          />
          <p>Small</p>
          {/* <p>Price: $0.7452</p> */}
        </div>

        <div
          onClick={() => handleDivClick(2, 1.104)}
          style={{
            border:
              selectedDiv === 2 ? "2px solid blue" : "2px solid transparent",
            cursor: "pointer",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: "10px",
            borderRadius: "10px",
          }}
        >
          <img
            src={medium}
            alt="Image 1"
            style={{ width: "100px", height: "100px" }}
          />
          <p>Medium</p>
          {/* <p>Price: $1.104</p> */}
        </div>
        <div
          onClick={() => handleDivClick(3, 1.293)}
          style={{
            border:
              selectedDiv === 3 ? "2px solid blue" : "2px solid transparent",
            cursor: "pointer",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: "10px",
            borderRadius: "10px",
          }}
        >
          <img
            src={large}
            alt="Image 1"
            style={{ width: "100px", height: "100px" }}
          />
          <p>Large</p>
          {/* <p>Price: $1.293</p> */}
        </div>
      </div>
    </div>
  );
};

export const CopiesDetails = ({ onChange }) => {
  const { id } = useParams();
  const [noofcopies, setNoofcopies] = useState("");

  const handleInputChange = (type, value) => {
    if (type === "noofcopies") {
      setNoofcopies(value);
      onChange(type, value);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "50vh",
        backgroundColor: "#f9f9f9",
        padding: "20px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "#fff",
          borderRadius: "10px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          padding: "20px",
          width: "100%",
          maxWidth: "400px",
        }}
      >
        <h5 style={{ textAlign: "center", marginBottom: "20px" }}>Copies</h5>
        <div style={{ width: "100%" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: "10px",
              width: "100%",
            }}
          >
            <label style={{ marginBottom: "5px", fontSize: "14px" }}>
              Number of Copies:
            </label>
            <input
              type="number"
              value={noofcopies}
              onChange={(e) => handleInputChange("noofcopies", e.target.value)}
              style={{
                width: "100%",
                padding: "10px",
                border: "1px solid #ccc",
                borderRadius: "5px",
                fontSize: "16px",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export const PagesColorDetail = ({ onChange, selectedPageSize }) => {
  const [printModes, setPrintModes] = useState({});

  const handleInputChange = (type, value) => {
    if (type === "printModes") {
      onChange(type, printModes);
    }
  };
  console.log(printModes);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "5px",
        transform: "scale(0.6)",
      }}
    >
      <h5 style={{ textAlign: "center" }}>Page Color</h5>
      <div style={{ display: "flex" }}>
        <PrintPreview
          selectedPageSize={selectedPageSize}
          showButton={false}
          showRadio={true}
          printModes={printModes}
          setPrintModes={setPrintModes}
          handleInputChange={handleInputChange}
        />
      </div>
    </div>
  );
};
