// Import the email verification action types
import {
    VERIFY_EMAIL_REQUEST,
    VERIFY_EMAIL_SUCCESS,
    VERIFY_EMAIL_FAILURE,
  } from '../actiontype/actiontype';
  
  // Add email verification related state
  const initialState = {
    // ... existing state properties
    verifyingEmail: false,
    emailVerificationError: null,
  };
  
  const verifyEmail = (state = initialState, action) => {
    switch (action.type) {
      // ... existing cases
  
      // Add email verification cases
      case VERIFY_EMAIL_REQUEST:
        return {
          ...state,
          verifyingEmail: true,
          emailVerificationError: null,
        };
      case VERIFY_EMAIL_SUCCESS:
        return {
          ...state,
          verifyingEmail: false,
          emailVerificationError: null,
        };
      case VERIFY_EMAIL_FAILURE:
        return {
          ...state,
          verifyingEmail: false,
          emailVerificationError: action.payload,
        };
  
      default:
        return state;
    }
  };
  
  export default verifyEmail;
  