import {
    FORGET_PASSWORD_REQUEST,
    FORGET_PASSWORD_SUCCESS,
    FORGET_PASSWORD_FAILURE,
  } from "../actiontype/actiontype";
  
  const initialState = {
    loading: false,
    error: null,
  };
  
  const authreducer = (state = initialState, action) => {
    switch (action.type) {
      case FORGET_PASSWORD_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
  
      case FORGET_PASSWORD_SUCCESS:
        return {
          ...state,
          loading: false,
          error: null,
        };
  
      case FORGET_PASSWORD_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
  
      default:
        return state;
    }
  };
  
  export default authreducer;
  