// reducers.js
import { FETCH_ORDER_SUCCESS, FETCH_ORDER_FAILURE } from '../actiontype/actiontype';

const initialState = {
  orderData: null,
  error: null
};

const editororderReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ORDER_SUCCESS:
      return {
        ...state,
        orderData: action.payload,
        error: null
      };
    case FETCH_ORDER_FAILURE:
      return {
        ...state,
        orderData: null,
        error: action.payload
      };
    default:
      return state;
  }
};

export default editororderReducer;
