import React, { useRef, useState } from 'react';
import { styled } from "@mui/material/styles";
import AddIcon from '@mui/icons-material/Add';


const UploadButton = styled('button')(({ theme }) => ({
  width: '200px',
  height: '200px',
  backgroundColor: 'rgba(128, 128, 128, 0.3)', // Grey color with low opacity
  color: theme.palette.common.white,
  border: 'none',
  borderRadius: '8px',
  display: 'flex',
  alignItems: 'center',
  color: 'black',
  justifyContent: 'center',
  flexDirection: 'column',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: 'rgba(128, 128, 128, 0.7)', // Slightly darker on hover
  },
}));



export default function Softcover({
  color,
  frontCoverImage,
  setFrontCoverImage,
  backCoverImage,
  setBackCoverImage,
  handleCoverClick,
  handleDragOver,
  coverHeight,
  coverWidth,
  frontZoom,
  backZoom,
  renderTextAreas,
  frontcoverRef
}) {
  const frontInputRef = useRef(null);
  const backInputRef = useRef(null);
  const [frontImagePosition, setFrontImagePosition] = useState({ top: 0 });
  const [backImagePosition, setBackImagePosition] = useState({ top: 0 });


  const handleFrontButtonClick = () => {
    frontInputRef.current.click();
  };

  const handleBackButtonClick = () => {
    backInputRef.current.click();
  };

  const handleFileChange = (event, setImage) => {
    const imageFile = event.target.files[0];
    if (imageFile) {
      const imageUrl = URL.createObjectURL(imageFile);
      setImage(imageUrl);
    }
  };

  const handleDragOverEvent = (event) => {
    event.preventDefault();
    handleDragOver(event);
  };

  const handleDropEvent = (event, setImage) => {
    event.preventDefault();
    const imageFile = event.dataTransfer.files[0];
    if (imageFile) {
      const imageUrl = URL.createObjectURL(imageFile);
      setImage(imageUrl);
    }
  };

  const handleDragStart = (event, imagePosition, setImagePosition) => {
    const { top } = imagePosition;
    const startY = event.clientY;
    event.dataTransfer.setDragImage(new Image(), 0, 0);

    const handleDrag = (e) => {
      const newTop = top + e.clientY - startY;
      setImagePosition({ top: newTop });
    };

    const handleDragEnd = () => {
      document.removeEventListener('dragover', handleDrag);
      document.removeEventListener('dragend', handleDragEnd);
    };

    document.addEventListener('dragover', handleDrag);
    document.addEventListener('dragend', handleDragEnd);
  };


  return (
    <>
      <div className="bleed-area"
           style={{
             backgroundColor: color,
             backgroundImage: frontCoverImage ? `url(${frontCoverImage})` : 'none',
             backgroundSize: frontZoom > 1.3 ? `${frontZoom * 100}% ${frontZoom * 100}%` : 'cover',
             backgroundPosition: `center ${frontImagePosition.top}px`,
             backgroundRepeat: 'no-repeat',
           }}
           onDrop={(e) => handleDropEvent(e, setFrontCoverImage)}
           onDragOver={handleDragOverEvent}
           onClick={(e) => handleCoverClick(e, 'front')}
           ref={frontcoverRef}
           title="Drag and drop an image to set the front cover"
      >
        <div className="caution-area">
          <div className="book-layout">
            <div
              className="book-cover"
              style={{
                width: coverWidth,
                height: coverHeight,
              }}
              title="Upload Cover"
            >
              <UploadButton className="upload-button" onClick={handleFrontButtonClick}>
                <AddIcon fontSize="large" />
                <span>Upload or Drop the Image here</span>
              </UploadButton>
              <input
                type="file"
                ref={frontInputRef}
                style={{ display: 'none' }}
                onChange={(e) => handleFileChange(e, setFrontCoverImage)}
              />
            </div>

            <div
              className="draggable"
              draggable
              onDragStart={(e) => handleDragStart(e, frontImagePosition, setFrontImagePosition)}
              style={{
                width: coverWidth,
                height: coverHeight,
                position: 'absolute',
                top: frontImagePosition.top,
              }}
            />
            <div className="book-front-spine" style={{ height: coverHeight }} title="Spine"></div>
            {renderTextAreas('front')}
          </div>
        </div>
      </div>
      <div className="bleed-area-right"
           style={{
             backgroundColor: color,
             backgroundImage: backCoverImage ? `url(${backCoverImage})` : 'none',
             backgroundSize: backZoom > 1.3 ? `${backZoom * 100}% ${backZoom * 100}%` : 'cover',
             backgroundPosition: `center ${backImagePosition.top}px`,
             backgroundRepeat: 'no-repeat',
           }}
           onDrop={(e) => handleDropEvent(e, setBackCoverImage)}
           onDragOver={handleDragOverEvent}
           onClick={(e) => handleCoverClick(e, 'back')}
           title="Drag and drop an image to set the back cover"
      >
        <div className="caution-area-right">
          <div className="book-layout">
            <div className="book-back-spine" style={{ height: coverHeight }} title="Spine"></div>
            <div
              className="book-back-cover"
              style={{
                width: coverWidth,
                height: coverHeight,
              }}
              title="Back Cover"
            >
              <UploadButton className="upload-button" onClick={handleBackButtonClick}>
                <AddIcon fontSize="large" />
                <span>Upload or Drop the Image here</span>
              </UploadButton>
              <input
                type="file"
                ref={backInputRef}
                style={{ display: 'none' }}
                onChange={(e) => handleFileChange(e, setBackCoverImage)}
              />
            </div>
            <div
              className="draggable"
              draggable
              onDragStart={(e) => handleDragStart(e, backImagePosition, setBackImagePosition)}
              style={{
                width: coverWidth,
                height: coverHeight,
                position: 'absolute',
                top: backImagePosition.top,
              }}
            />
            {renderTextAreas('back')}
          </div>
        </div>
      </div>
    </>
  );
}
