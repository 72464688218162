import React, { useState, useEffect, useMemo } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TocIcon from "@mui/icons-material/Toc";
import TitleIcon from "@mui/icons-material/Title";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Tooltip from "@mui/material/Tooltip";
import TextEditor from "./TextEditor";
import { useSelector, useDispatch } from "react-redux";
import {
  getSingleBookChapter,
  addingNewChapter,
  deleteChapter,
  addSubChapter,
  deleteSubchapter,
} from "../redux/action/action";
import CopyrightIcon from "@mui/icons-material/Copyright";
import { useParams } from "react-router-dom";
import Popover from "@mui/material/Popover";
import Contents from "./Contents";
import TitlePage from "./TitlePage";
import Copyright from "./Copyright";
import Preface from "./Preface";
import Foreward from "./Foreward";
import Introduction from "./Introduction";
import Dedication from "./Dedication";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import CircularProgress from "@mui/material/CircularProgress";

import Loading from "./Loading";

export default function EditorSidebar() {
  const [selectedItem, setSelectedItem] = useState(0);
  const [isContentsSelected, setIsContentsSelected] = useState(false);
  const [isCopyrightSelected, setIsCopyrightSelected] = useState(false);
  const [isTitlePageSelected, setIsTitlePageSelected] = useState(false);
  const [isDedicationSelected, setIsDedicationSelected] = useState(false);
  const [isPrefaceSelected, setIsPrefaceSelected] = useState(false);
  const [isForewordSelected, setIsForewordSelected] = useState(false);
  const [isIntroductionSelected, setIsIntroductionSelected] = useState(false);
  const [open, setOpen] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isSubChapter, setIsSubChapter] = useState(false);
  const [selectedSubChapter, setSelectedSubChapter] = useState(0);
  const [showSubChapt, setShowSubChapt] = useState(false);
  const [showPopover, setShowPopover] = useState(false);
  const [showArray, setShowArray] = useState({});
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const chapters = useSelector(
    (state) => state?.reducer?.singleBook?.response?.chapters
  );

  const params = useParams();
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.reducer.loading);
  const loadingChapter = useSelector(
    (state) => state.addchapterreducer.loading
  );
  const loadingSubChapter = useSelector(
    (state) => state.addsubchapterreducer.loading
  );

  useEffect(() => {
    dispatch(getSingleBookChapter(params.id));
  }, [params]);

  // useEffect(() => {
  //   if (chapters === undefined) {
  //     return;
  //   } else if (chapters[selectedItem]?.subchapters.length === 0) {
  //     setShowSubChapt(null);
  //     setIsSubChapter(false);
  //   }

  //   setShowArray(new Array(chapters.length).fill(false));
  // }, [chapters]);

  const chapterDeleteHandler = (id) => {
    if (window.confirm("Are you sure you want to delete?")) {
      dispatch(deleteChapter(params.id, id));
      setSelectedItem(0);
      setAnchorEl(null);
    } else console.log("nothing");
  };

  const subChapterDeleteHandler = (id) => {
    if (window.confirm("Are you sure you want to delete?")) {
      dispatch(deleteSubchapter(params.id, id));
      setSelectedSubChapter(0);
    }
  };

  return (
    <>
      <Box sx={{ display: "flex", position: "relative" }}>
        <CssBaseline />

        <div variant="permanent" className="col-2" open={open}>
          {/* <DrawerHeader sx={{ background: "#E8F2FD" }}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </DrawerHeader> */}
          <List sx={{ background: "#E8F2FD" }}>
            {/* {["Title Page", "Copyright", "Contents"].map((text, index) => ( */}
            <ListItem
              selected={selectedItem}
              // key={index}
              disablePadding
              onClick={() => {
                setIsContentsSelected(false);
                setIsCopyrightSelected(false);
                setIsTitlePageSelected(true);
                setIsDedicationSelected(false);
                setIsForewordSelected(false);
                setIsPrefaceSelected(false);
                setIsIntroductionSelected(false);
                // setSelectedItem(index);
                // setIsSubChapter(false);
              }}
              sx={{ display: "block" }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {<TitleIcon />}
                </ListItemIcon>
                <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                  Title Page
                </ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem
              selected={selectedItem}
              // key={index}
              disablePadding
              onClick={() => {
                setIsContentsSelected(false);
                setIsCopyrightSelected(true);
                setIsTitlePageSelected(false);
                setIsDedicationSelected(false);
                setIsForewordSelected(false);
                setIsPrefaceSelected(false);
                setIsIntroductionSelected(false);
                // setSelectedItem(index);
                // setIsSubChapter(false);
              }}
              sx={{ display: "block" }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {<CopyrightIcon />}
                </ListItemIcon>
                <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                  Copyright
                </ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem
              selected={selectedItem}
              // key={index}
              disablePadding
              onClick={() => {
                setIsContentsSelected(false);
                setIsCopyrightSelected(false);
                setIsTitlePageSelected(false);
                setIsDedicationSelected(true);
                setIsForewordSelected(false);
                setIsPrefaceSelected(false);
                setIsIntroductionSelected(false);
                // setSelectedItem(index);
                // setIsSubChapter(false);
              }}
              sx={{ display: "block" }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {<TocIcon />}
                </ListItemIcon>
                <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                  Dedication
                </ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem
              selected={selectedItem}
              // key={index}
              disablePadding
              onClick={() => {
                setIsContentsSelected(true);
                setIsCopyrightSelected(false);
                setIsTitlePageSelected(false);
                setIsDedicationSelected(false);
                setIsForewordSelected(false);
                setIsPrefaceSelected(false);
                setIsIntroductionSelected(false);
                // setSelectedItem(index);
                // setIsSubChapter(false);
              }}
              sx={{ display: "block" }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {<TocIcon />}
                </ListItemIcon>
                <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                  Table of Content
                </ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem
              selected={selectedItem}
              // key={index}
              disablePadding
              onClick={() => {
                setIsContentsSelected(false);
                setIsCopyrightSelected(false);
                setIsTitlePageSelected(false);
                setIsDedicationSelected(false);
                setIsForewordSelected(true);
                setIsPrefaceSelected(false);
                setIsIntroductionSelected(false);
                // setSelectedItem(index);
                // setIsSubChapter(false);
              }}
              sx={{ display: "block" }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {<TocIcon />}
                </ListItemIcon>
                <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                  Foreword
                </ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem
              selected={selectedItem}
              // key={index}
              disablePadding
              onClick={() => {
                setIsContentsSelected(false);
                setIsCopyrightSelected(false);
                setIsTitlePageSelected(false);
                setIsDedicationSelected(false);
                setIsForewordSelected(false);
                setIsPrefaceSelected(true);
                setIsIntroductionSelected(false);
                // setSelectedItem(index);
                // setIsSubChapter(false);
              }}
              sx={{ display: "block" }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {<TocIcon />}
                </ListItemIcon>
                <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                  Preface
                </ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem
              selected={selectedItem}
              // key={index}
              disablePadding
              onClick={() => {
                setIsContentsSelected(false);
                setIsCopyrightSelected(false);
                setIsTitlePageSelected(false);
                setIsDedicationSelected(false);
                setIsForewordSelected(false);
                setIsPrefaceSelected(false);
                setIsIntroductionSelected(true);
                // setSelectedItem(index);
                // setIsSubChapter(false);
              }}
              sx={{ display: "block" }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {<TocIcon />}
                </ListItemIcon>
                <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                  Introduction
                </ListItemText>
              </ListItemButton>
            </ListItem>
            {/* ))} */}
          </List>
          <List sx={{ background: "#E8F2FD" }}>
            {chapters?.map((text, index) => (
              <ListItem
                selected={!isContentsSelected}
                key={text._id}
                disablePadding
                sx={{ display: "block" }}
                onClick={() => {
                  setIsContentsSelected(false);
                  setIsCopyrightSelected(false);
                  setIsTitlePageSelected(false);
                  setIsDedicationSelected(false);
                  setIsForewordSelected(false);
                  setIsPrefaceSelected(false);
                  setIsIntroductionSelected(false);
                  // setSelectedItem(index);
                  // setIsSubChapter(false);
                }}
                // onClick={() => handleChange(index)}
              >
                <Tooltip title={text?.chaptername}>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <div className="d-flex align-items-center w-100 justify-content-between flex-column">
                      <div
                        className="d-flex align-items-center flex-row w-100"
                        onClick={() => {
                          setSelectedItem(index);
                          setIsSubChapter(false);
                        }}
                      >
                        {/* <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        {/ {`${index + 1}.`} /}
                      {/ </ListItemIcon>  /}
                      {/* {text.subchapters.length > 0 && (
                        <KeyboardArrowRightIcon
                          onClick={() => {
                            setShowSubChapt(true);
                            toggleValue(index);
                          }}
                        />
                      )} */}
                        <KeyboardArrowDownIcon
                          variant="contained"
                          onClick={(e) => {
                            setSelectedItem(index);
                            handleClick(e);
                            setShowPopover(true);
                          }}
                        />
                        <ListItemText
                          primary={
                            text?.chaptername?.length > 20
                              ? `${text.chaptername.slice(0, 20)}...`
                              : `${text.chaptername}`
                          }
                          sx={{
                            opacity: open ? 1 : 0,
                            marginLeft: "24px",
                            fontFamily: "Poppins !important",
                          }}
                        />
                      </div>
                    </div>
                  </ListItemButton>
                </Tooltip>
                {showArray[index] && (
                  <div style={{ width: "85%", marginLeft: "20px" }}>
                    {text.subchapters.map((sub, index) => (
                      <ListItemButton
                        className="d-flex align-items-center w-100 justify-content-between"
                        key={sub._id}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                          }}
                        >
                          {`${index + 1}.`}
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            sub.subchaptertitle.length > 14
                              ? `${sub.subchaptertitle.slice(0, 13)}...`
                              : `${sub.subchaptertitle}`
                          }
                          sx={{ opacity: open ? 1 : 0 }}
                          onClick={() => {
                            setIsSubChapter(true);
                            setSelectedSubChapter(index);
                          }}
                        />
                        <DeleteOutlineIcon
                          onClick={() => subChapterDeleteHandler(sub._id)}
                        />
                      </ListItemButton>
                    ))}
                  </div>
                )}
              </ListItem>
            ))}
          </List>
          <div>
            <Popover
              id={1}
              open={showPopover}
              anchorEl={anchorEl}
              onClose={() => setShowPopover(false)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <ListItemButton
                sx={{ p: 2, cursor: "pointer" }}
                onClick={() => {
                  chapterDeleteHandler(chapters[selectedItem]?._id);
                  setShowPopover(false);
                }}
              >
                Delete
              </ListItemButton>
              {/* <ListItemButton
                disabled={loadingSubChapter}
                onClick={() => {
                  dispatch(
                    addSubChapter(
                      chapters[selectedItem]?._id,
                      `Subchapter ${
                        chapters[selectedItem]?.subchapters.length + 1
                      }`,
                      "",
                      params.id
                    )
                  );
                  setAnchorEl(null);
                  setShowPopover(false);
                }}
              >
                Add subchapter
                {loadingSubChapter && (
                  <CircularProgress
                    size={20}
                    color='inherit'
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                )}
              </ListItemButton> */}
            </Popover>
          </div>
          <List>
            <ListItem
              disablePadding
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Button
                onClick={() => {
                  dispatch(
                    addingNewChapter(
                      params.id,
                      `Chapter ${chapters?.length + 1}`,
                      ""
                    )
                  );
                }}
                disabled={loadingChapter}
              >
                <AddCircleOutlineIcon
                  sx={{
                    fontSize: "50px",
                    color: "#1C212D",
                  }}
                >
                  {loadingChapter && (
                    <CircularProgress
                      size={20}
                      color="inherit"
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  )}
                </AddCircleOutlineIcon>
              </Button>
            </ListItem>
          </List>
        </div>
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          {loading && !chapters ? (
            <Loading />
          ) : isCopyrightSelected ? (
            <Copyright />
          ) : isDedicationSelected ? (
            <Dedication />
          ) : isTitlePageSelected ? (
            <TitlePage />
          ) : isContentsSelected ? (
            <Contents title={chapters} />
          ) : isForewordSelected ? (
            <Foreward />
          ) : isPrefaceSelected ? (
            <Preface />
          ) : isIntroductionSelected ? (
            <Introduction />
          ) : (
            chapters && (
              <TextEditor
                key={selectedItem}
                index={selectedItem}
                text={chapters[selectedItem]?.chapterbody}
                title={chapters[selectedItem]?.chaptername}
                chapterId={chapters[selectedItem]?._id}
                isSubChapter={isSubChapter}
                subText={
                  chapters[selectedItem]?.subchapters[selectedSubChapter]
                    ?.subchapterbody
                }
                subTitle={
                  chapters[selectedItem]?.subchapters[selectedSubChapter]
                    ?.subchaptertitle
                }
                subChapterId={
                  chapters[selectedItem]?.subchapters[selectedSubChapter]?._id
                }
                subIndex={selectedSubChapter}
              />
            )
          )}
        </Box>
      </Box>
    </>
  );
}
