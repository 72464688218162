import React, { useState } from 'react';
import './Toolbar.css';

const Menu = ({ changeColor, zoomIn, zoomOut, onSoftCoverDimensionChange,onHardCoverDimensionChange, togglePreviewMode, isPreviewMode, onAddTextArea,saveCoverAsImage, }) => {
  const [softCoverOpen, setSoftCoverOpen] = useState(false);
  const [hardCoverOpen, setHardCoverOpen] = useState(false);

  const handleSoftCoverDimensionClick = (width, height) => {
    onSoftCoverDimensionChange(width, height);
    console.log(`Selected dimensions: ${width} x ${height}`);
  };
const handleHardCoverDimensionClick = (width,height)=>{
  onHardCoverDimensionChange(width, height);
  console.log(`Selected dimensions: ${width} x ${height}`);
}
  const toggleSoftCover = () => {
    setSoftCoverOpen((prevOpen) => !prevOpen);
    if (hardCoverOpen) {
      setHardCoverOpen(false); // Close hard cover menu if soft cover is opened
    }
  };

  const toggleHardCover = () => {
    setHardCoverOpen((prevOpen) => !prevOpen);
    if (softCoverOpen) {
      setSoftCoverOpen(false); // Close soft cover menu if hard cover is opened
    }
  };

  return (
    <div className="menu1">
      <div className="column">
        <div className="soft-cover" style={{ marginBottom: "15px" }}>
          <div className="soft-cover-header" onClick={toggleSoftCover}>
            Soft Cover
            <span className={`arrow ${softCoverOpen ? 'open' : ''}`}>&#9660;</span>
          </div>
          <div className={`soft-cover-options ${softCoverOpen ? 'open' : ''}`}>
            <div className="menu-heading">Portrait</div>
            <button className="menu-button" onClick={() => handleSoftCoverDimensionClick('8.5in', '11in')}>
              8.5x11
            </button>
            <button className="menu-button" onClick={() => handleSoftCoverDimensionClick('8in', '10in')}>
              8x10
            </button>
            <button className="menu-button" onClick={() => handleSoftCoverDimensionClick('6in', '9in')}>
              6x9
            </button>
            <div className="menu-heading">Landscape</div>
            <button className="menu-button" onClick={() => handleSoftCoverDimensionClick('11in', '8.5in')}>
              11x8.5
            </button>
            <button className="menu-button" onClick={() => handleSoftCoverDimensionClick('10in', '8in')}>
              10x8
            </button>
            <button className="menu-button" onClick={() => handleSoftCoverDimensionClick('9in', '6in')}>
              9x6
            </button>
          </div>
        </div>
      </div>
      <div className="column">
        <div className="hard-cover" style={{ marginBottom: "15px" }}>
          <div className="hard-cover-header" onClick={toggleHardCover}>
            Hard Cover
            <span className={`arrow ${hardCoverOpen ? 'open' : ''}`}>&#9660;</span>
          </div>
          <div className={`hard-cover-options ${hardCoverOpen ? 'open' : ''}`}>
            <div className="menu-heading">Portrait</div>
            <button className="menu-button" onClick={() => handleHardCoverDimensionClick('8.5in', '11in')}>
              8.5x11
            </button>
            <button className="menu-button" onClick={() => handleHardCoverDimensionClick('8in', '10in')}>
              8x10
            </button>
            <button className="menu-button" onClick={() => handleHardCoverDimensionClick('6in', '9in')}>
              6x9
            </button>
            <div className="menu-heading">Landscape</div>
            <button className="menu-button" onClick={() => handleHardCoverDimensionClick('11in', '8.5in')}>
              11x8.5
            </button>
            <button className="menu-button" onClick={() => handleHardCoverDimensionClick('10in', '8in')}>
              10x8
            </button>
            <button className="menu-button" onClick={() => handleHardCoverDimensionClick('9in', '6in')}>
              9x6
            </button>
          </div>
        </div>
      </div>
      <div className="column">
        <div className="menu-heading">Zoom</div>
        <button className="menu-button" onClick={zoomIn}>
          Zoom In
        </button>
        <button className="menu-button" onClick={zoomOut}>
          Zoom Out
        </button>
      </div>
      {/* <div className="column">
        <div className="menu-heading">Color</div>
        <input type="color" onChange={(e) => changeColor(e.target.value)} />
      </div> */}
      <div className="column">
        <button className="menu-button" onClick={onAddTextArea}>
          Text Area
        </button>
      </div>
      <div className="column">
        <button onClick={togglePreviewMode} className="preview-button">
          {isPreviewMode ? 'Exit Preview' : 'Preview'}
        </button>
      </div>
      <button onClick={saveCoverAsImage}>Save Cover Image</button>
    </div>
  );
};

export default Menu;
