import React, { useState } from "react";
import Button from "@mui/material/Button";
import { yearActionAI } from "../api/api";
import CircularProgress from "@mui/material/CircularProgress";

export default function YearButton({ action, setResponseAI }) {
  const [year, setYear] = useState("");
  const [loading, setLoading] = React.useState(false);

  const handleClick = async (event) => {
    if (year === "") {
      return;
    } else {
      try {
        console.log(action + year);
        setLoading(true);
        const res = await yearActionAI(action + " " + year);
        setResponseAI(res.data);
        setLoading(false);
      } catch (err) {
        window.alert("Something went wrong ");
        setLoading(false);
      }
    }
  };

  const onChangeHandler = (e) => {
    setYear(e.target.value);
  };
  return (
    <div className="w-100">
      <Button
        id="demo-customized-button"
        variant="outlined"
        className="w-100"
        disableFocusRipple
        disableRipple
        sx={{ fontSize: 9, display: "inline",height:"11vh",border:"2px solid #503981",color:"#503981",fontFamily:"poppins" }}
        onClick={handleClick}
        disabled={loading}
      >
        {action}
        &nbsp;{" "}
        <input
          style={{ width: "40px" , border:"1px solid #503981", borderRadius:"6px", textAlign:"center"}}
          type="number"
          placeholder="year"
          onChange={onChangeHandler}
        />
        {loading && (
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
              width: "100%",
              height: "100%",
              marginTop: "-25px",
              marginBottom: "21px",
              position: "relative",
              top: "23%",
            }}
          >
            <CircularProgress color="inherit" size={16} />
          </div>
        )}
      </Button>
    </div>
  );
}
