import React, { useState } from "react";
import axios from "axios";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import Box from "@mui/material/Box";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

function OrdersTable({ orders, handleOrderDelete, updateOrders }) {
  const [selectedStatus, setSelectedStatus] = useState({});
  const [openRow, setOpenRow] = useState({});

  const handleChange = (event, orderId) => {
    setSelectedStatus({ ...selectedStatus, [orderId]: event.target.value });
  };

  const sortedOrders = orders.slice().sort((a, b) => {
    return new Date(b.date) - new Date(a.date);
  });

  const URL = "https://app.simplymemoirs.com/api";
  const handleSaveChanges = async (orderId) => {
    try {
      const authToken = localStorage.getItem("token");
      const newStatus = selectedStatus[orderId];

      const response = await axios.patch(
        `${URL}/statusadmin/${orderId}`,
        { status: newStatus },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      console.log("Order status updated:", response.data);
      updateOrders();
    } catch (error) {
      console.error("Error updating order status:", error);
    }
  };

  const handleRowClick = (orderId) => {
    setOpenRow({ ...openRow, [orderId]: !openRow[orderId] });
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 600 }} aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell style={{ fontWeight: "bold" }}>Order</TableCell>
            <TableCell style={{ fontWeight: "bold" }}>Date Created</TableCell>
            <TableCell style={{ fontWeight: "bold" }}>Last Updated</TableCell>
            <TableCell align="center" style={{ fontWeight: "bold" }}>
              Status
            </TableCell>
            <TableCell align="center" style={{ fontWeight: "bold" }}>
              Change Status
            </TableCell>
            <TableCell align="center" style={{ fontWeight: "bold" }}>
              Delete
            </TableCell>
            <TableCell align="center" style={{ fontWeight: "bold" }}>
              Save
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedOrders.map((row, index) => (
            <React.Fragment key={index}>
              <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                <TableCell>
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => handleRowClick(row._id)}
                  >
                    {openRow[row._id] ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </IconButton>
                </TableCell>
                <TableCell>{row.orderName}</TableCell>
                <TableCell>{row.date.slice(0, 10)}</TableCell>
                <TableCell>{row.date.slice(0, 10)}</TableCell>
                <TableCell align="center">
                  <div
                    style={{
                      borderRadius: "25px",
                      margin: "0 auto",
                      padding: "5px",
                      width: "60%",
                    }}
                  >
                    {row.status}
                  </div>
                </TableCell>
                <TableCell align="center">
                  <Select
                    value={selectedStatus[row._id] || ""}
                    onChange={(event) => handleChange(event, row._id)}
                  >
                    <MenuItem value="Printing">Printing</MenuItem>
                    <MenuItem value="Printed">Printed</MenuItem>
                    <MenuItem value="Dispatched">Dispatched</MenuItem>
                    <MenuItem value="Delivered">Delivered</MenuItem>
                  </Select>
                </TableCell>
                <TableCell align="center">
                  <Button
                    onClick={() => handleOrderDelete(row._id)}
                    color="inherit"
                  >
                    <DeleteOutlineIcon color="error" />
                  </Button>
                </TableCell>
                <TableCell align="center">
                  <Button
                    style={{ backgroundColor: "black", color: "white" }}
                    onClick={() => handleSaveChanges(row._id)}
                  >
                    Save Changes
                  </Button>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                  colSpan={9}
                >
                  <Collapse in={openRow[row._id]} timeout="auto" unmountOnExit>
                    <Box margin={1}>
                      <div>
                        <h4>Additional details</h4>
                      </div>
                      <Table size="small" aria-label="purchases">
                        <TableBody>
                          <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                            <TableCell>Book Size</TableCell>
                            <TableCell>{row.bookSize?.type}</TableCell>
                          </TableRow>
                          <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                            <TableCell>Book Type</TableCell>
                            <TableCell>{row.bookType?.type}</TableCell>
                          </TableRow>
                          <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                            <TableCell>Orientation</TableCell>
                            <TableCell>{row.orientation?.type}</TableCell>
                          </TableRow>
                          <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                            <TableCell>Paper Weight</TableCell>
                            <TableCell>{row.paperWeight?.type}</TableCell>
                          </TableRow>
                          <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                            <TableCell>Print Quality</TableCell>
                            <TableCell>{row.printQuality?.type}</TableCell>
                          </TableRow>
                          <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                            <TableCell>Delivery Method</TableCell>
                            <TableCell>{row.deliveryMethod?.type}</TableCell>
                          </TableRow>
                          <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                            <TableCell>Cover Finish</TableCell>
                            <TableCell>{row.coverFinish?.type}</TableCell>
                          </TableRow>
                          <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                            <TableCell>B/W Pages</TableCell>
                            <TableCell>
                              {Object.keys(row.printModes?.type || {})
                                .filter(
                                  (key) => row.printModes?.type[key] === "bw"
                                )
                                .map((key, index, arr) => (
                                  <span key={key}>
                                    {key}
                                    {index < arr.length - 1 && ", "}
                                  </span>
                                ))}
                            </TableCell>
                          </TableRow>
                          <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                            <TableCell>Colored Pages</TableCell>
                            <TableCell>
                              {Object.keys(row.printModes?.type || {})
                                .filter(
                                  (key) => row.printModes?.type[key] === "color"
                                )
                                .map((key, index, arr) => (
                                  <span key={key}>
                                    {key}
                                    {index < arr.length - 1 && ", "}
                                  </span>
                                ))}
                            </TableCell>
                          </TableRow>
                          <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                            <TableCell>Copies</TableCell>
                            <TableCell>{row.noofcopies?.type}</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default OrdersTable;
