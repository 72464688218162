import {
    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILURE,
  } from "../actiontype/actiontype";
  

  const initialState = {
    resetting: false,
    resetSuccess: false,
    resetError: null,
  };
  
  const resetPasswordReducer = (state = initialState, action) => {
    switch (action.type) {
      case RESET_PASSWORD_REQUEST:
        return {
          ...state,
          resetting: true,
          resetSuccess: false,
          resetError: null,
        };
  
      case RESET_PASSWORD_SUCCESS:
        return {
          ...state,
          resetting: false,
          resetSuccess: true,
          resetError: null,
        };
  
      case RESET_PASSWORD_FAILURE:
        return {
          ...state,
          resetting: false,
          resetSuccess: false,
          resetError: action.payload,
        };
  
      // Handle other action types as needed
  
      default:
        return state;
    }
  };
  
  export default resetPasswordReducer;
  