import React, { useEffect, useState } from "react";
import Signinimg from "../images/bookssign.png";
import GoogleButton from "react-google-button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LockIcon from "@mui/icons-material/Lock";
import axios from "axios";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {
  signInUser,
  clearState,
  signinWithGoogle,
} from "../redux/action/action";
import { useDispatch, useSelector } from "react-redux";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  getAuth,
  onAuthStateChanged,
  setPersistence,
  browserSessionPersistence,
} from "firebase/auth";
import { Height } from "@mui/icons-material";
import { red } from "@mui/material/colors";
import { jwtDecode } from "jwt-decode";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#503981",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#503981",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#503981",
      borderRadius: "10px",
    },
    "&:hover fieldset": {
      borderColor: "#503981",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#503981",
    },
    "& input": {
      height: "0.7rem",
      fontFamily: "Poppins",
      fontSize: "14px",
    },
  },
  "& .MuiFormHelperText-root": {
    fontSize: "0.6rem",
    color: "black",
  },
});

const ColorButton = styled(Button)(() => ({
  width: "18%",
  fontSize: "1vw",
  fontFamily: "Poppins",
  backgroundImage: "linear-gradient(99deg, #6A1AC3 28.42%, #1D0A32 126.77%)",
  color: "white",
  "&:hover": {
    backgroundImage: "linear-gradient(99deg, #6A1AC3 28.42%, #1D0A32 126.77%)",
  },
}));
const customStyles = {
  row: {
    height: "100vh",
    "--bs-gutter-x": "0",
  },
  col: {
    height: "100%",
  },
};
const auth = getAuth();
export default function Signin() {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [showPass, setShowPass] = useState(false);
  const [users, setUsers] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.signinreducer.user);
  const error = useSelector((state) => state.signinreducer.error);
  const loading = useSelector((state) => state.signinreducer.loading);
  const user1 = useSelector((state) => state.Google.user);
  const error2 = useSelector((state) => state.Google.error); // const [isTokenExpired, setTokenExpired] = useState(false);
  //   console.log('user1:', user1);
  // console.log('error2:', error2);
  ///// checking if token available then go to books otherwise not //////////

  // ...

  useEffect(() => {
    // Set persistence to session
    setPersistence(auth, browserSessionPersistence)
      .then(() => {
        // Now, listen for changes in the authentication state
        const unsubscribe = onAuthStateChanged(auth, async (userAuth) => {
          if (userAuth) {
            // User logged in
            setUsers(userAuth);
          } else {
            // User logged out
            setUsers(null);
            navigate("/");
          }
        });
        return () => unsubscribe();
      })
      .catch((error) => {
        console.error("Error setting persistence:", error);
      });
  }, []);

  // const checkTokenExpiration = (user) => {
  //   user.getIdToken().then((token) => {
  //     const decodedToken = jwtDecode(token);
  //     const isExpired = decodedToken.exp * 1000 < Date.now();

  //     setTokenExpired(isExpired);
  //   });
  // };

  const authToken = users?.accessToken;
  useEffect(() => {
    console.log(authToken,"token");
    if (authToken !== null && authToken !== undefined) {
      toast.success("Login successful");

      axios({
        method: "get",
        // url: "http://localhost:3001/api/singleuser",
        url: "https://app.simplymemoirs.com/api/singleuser",

        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
        .then((response) => {
          if (!response.data) {
            navigate("/signin");
           } else {
            navigate("/books");
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });

      dispatch(clearState());
    }
  }, [user1, user,authToken]);

  function signInwithGoogle() {
    dispatch(signinWithGoogle());
    if (authToken) {
      toast.success("Login successful");
      navigate("/books");
    } else {
      navigate("/signin");
    }
  }

  const isEmailValid = (email) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    return emailRegex.test(email);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const newEmail = formData.email;
    if (!isEmailValid(newEmail)) {
      toast.error("Invalid Email");
      return;
    }
    if (formData.password.length < 6) {
      toast.error("Password must be at least 6 characters long.");
      return;
    }
    dispatch(signInUser(formData));
  };

  return (
    <form className='col-12' onSubmit={handleSubmit}>
      <div className='row' style={customStyles.row}>
        <div className='col-6' style={customStyles.col}>
          <img src={Signinimg} width='100%' height='100%' alt='Loading' />
        </div>
        <div className='col-6' style={customStyles.col}>
          <div className='w-100 h-100 justify-content-center align-items-center d-flex flex-column'>
            <Typography
              variant='h3'
              sx={{
                color: "#503981",
                fontWeight: "500",
                fontFamily: "Poppins",
                fontSize: "5.5vw",
                fontStyle: "normal",
                lineHeight: "70px",
              }}
            >
              Welcome
            </Typography>
            <Typography
              variant='p'
              sx={{
                color: "#503981",
                fontWeight: "400",
                fontFamily: "Poppins",
              }}
            >
              Login your account
            </Typography>
            <CssTextField
              id='outlined-basic'
              label='Email'
              name='email'
              value={formData.email}
              variant='outlined'
              sx={{ marginTop: 2, width: "43%" }}
              focused
              placeholder='Email'
              InputProps={{
                startAdornment: (
                  <MailOutlineIcon sx={{ marginRight: 1, color: "#503981" }} />
                ),
              }}
              onChange={handleInputChange}
            />
            <CssTextField
              id='outlined-basic'
              label='Password'
              name='password'
              variant='outlined'
              sx={{ marginTop: 2, width: "43%" }}
              InputProps={{
                startAdornment: (
                  <LockIcon sx={{ marginRight: 1, color: "#503981" }} />
                ),
                endAdornment: showPass ? (
                  <VisibilityIcon
                    onClick={() => setShowPass(!showPass)}
                    sx={{ cursor: "pointer", color: "#503981" }}
                  />
                ) : (
                  <VisibilityOffIcon
                    onClick={() => setShowPass(!showPass)}
                    sx={{ cursor: "pointer", color: "#503981" }}
                  />
                ),
              }}
              focused
              placeholder='Password'
              type={showPass ? "text" : "password"}
              value={formData.password}
              onChange={handleInputChange}
              helperText={
                "Password must be more than 6 characters with uppercase, lowercase and contain special characters."
              }
            />

            <div
              className='mt-2 d-flex justify-content-between'
              style={{ width: "45%" }}
            >
              <Link style={{ textDecoration: "none" }} to={"/signup"}>
                <Typography
                  variant='p'
                  sx={{
                    color: "#503981",
                    fontSize: "1vw",
                    fontFamily: "Poppins",
                  }}
                >
                  Register your account
                </Typography>
              </Link>
              <Link style={{ textDecoration: "none" }} to={"/forgot"}>
                <Typography
                  variant='p'
                  sx={{
                    color: "#503981",
                    fontSize: "1vw",
                    fontFamily: "Poppins",
                  }}
                >
                  Forgot Password?
                </Typography>
              </Link>
            </div>
            <ColorButton
              variant='contained'
              type='submit'
              sx={{ marginTop: 3 }}
              disabled={loading}
              onClick={handleSubmit}
            >
              Log in
            </ColorButton>
            <GoogleButton
              style={{ marginTop: 15 }}
              onClick={signInwithGoogle}
            />
          </div>
        </div>
      </div>
      <ToastContainer />
    </form>
  );
}
