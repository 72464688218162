import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { getAuth, signOut } from "firebase/auth";

export default function Privateroutes(props) {
  const navigate = useNavigate();
  const { Component } = props;
  const auth = getAuth();
  const authToken = localStorage.getItem("token");
  useEffect(() => {
    if (!authToken) {
      navigate("/signin");
    } else {
      const oneHourInMilliseconds = 50 * 60 * 1000;
      axios({
        method: "get",
        // url: "http://localhost:3001/api/singleuser",
        url: "https://app.simplymemoirs.com/api/singleuser",

        headers: {
          Authorization: `Bearer ${authToken}`, // Include your Firebase ID token here
        },
      })
        .then((response) => {
          if (!response.data) {
            navigate("/signin");
          } else if (response.data.userRole === 2) {
            const allowedAdminRoutes = [
              "/books",
              "/editor",
              "/settings",
              "/orderlist",
              "/orders",
              "/checkout",
              "/superadmin",
              "/paymentsuccessful",
              "/bookcovereditor"
            ];
            if (
              !allowedAdminRoutes.some((route) =>
                window.location.pathname.includes(route)
              )
            ) {
              navigate("/superadmin");
            }
          } else if (response.data.userRole === 1) {
            const allowedAdminRoutes = [
              "/admin",
              "/books",
              "/editor",
              "/settings",
              "/orderlist",
              "/orders",
              "/orders",
              "/checkout",
              "/epub",
              "/paymentsuccessful",
              "/bookcovereditor"

            ];
            if (
              !allowedAdminRoutes.some((route) =>
                window.location.pathname.includes(route)
              )
            ) {
              navigate("/admin");
            }
          } else if (response.data.userRole === 0) {
            const allowedRoutes = [
              "/editor",
              "/settings",
              "/orderlist",
              "/orders",
              "/epub",
              "/checkout",
              "/paymentsuccessful",
              "/bookcovereditor"
            ];
            if (
              !allowedRoutes.some((route) =>
                window.location.pathname.includes(route)
              )
            ) {
              navigate("/books");
            }
          }
        })

        .catch((error) => {
          // Handle errors here
          console.error("Error fetching data:", error);
        });

      const tokenExpirationTimeout = setTimeout(() => {
        signOut(auth).then(() => {
          localStorage.removeItem("token");
          navigate("/signin");
        }).catch((error) => {
          console.error("Error signing out:", error);
        });
      }, oneHourInMilliseconds);
      return () => tokenExpirationTimeout;
    }
  }, [authToken]);

  return (
    <div>
      <Component />
    </div>
  );
}