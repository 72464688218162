import React, { useEffect, useState } from "react";
import { fetchBookList } from "../redux/action/action";
import { useDispatch, useSelector } from "react-redux";
import Books from "../components/Books";

function Book() {
  const dispatch = useDispatch();
  const loading = useSelector((s) => s.reducer.loading);
  const err = useSelector((s) => s.reducer.error);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      dispatch(fetchBookList(token));
    }
  }, []);
  const handleBookUpdate = () => {
    const token = localStorage.getItem("token");
    dispatch(fetchBookList(token));
  };
  return (
    <div style={{ paddingLeft: "8%" }}>
      <div>
        <Books onBookUpdate={handleBookUpdate} />
      </div>
    </div>
  );
}

export default Book;
