import React from "react";
import "./ipad.css";
import parse from "html-react-parser";
function Ipad({ data, font }) {
  function removeEmptyPTags(content) {
    const doc = new DOMParser().parseFromString(content, "text/html");
    const pTags = doc.querySelectorAll("p");

    pTags.forEach((pTag) => {
      if (!pTag.textContent.trim()) {
        pTag.remove();
      } else {
        pTag.style.margin = "0";
      }
    });

    return doc.body.innerHTML;
  }

  const cleanedText = removeEmptyPTags(data);
  return (
    <div>
      <figure className='tablet portrait'>
        <div className='tablet__screen'>
          <div className='tablet__screenContainer'>
            <div className='wrap'>
              {/* <div className='top'>
                <div className='network'>
                  <div className='span' />
                  <div className='span' />
                  <div className='span' />
                  <div className='span' />
                  <div className='span' />
                </div>
                <div className='time'>1:20 PM</div>
                <div className='battery' />
              </div> */}
              <div
                // className=''
                style={{
                  ...font(),
                  padding: "10px",
                  whiteSpace: "pre-wrap",
                  wordWrap: "break-word",
                }}
              >
                {parse(data)}
              </div>
            </div>
          </div>
        </div>
        <div className='tablet__camera'>
          <span className='tablet__cameraEye' />
        </div>
        <div className='tablet__home'>
          <span className='tablet__homeSquare' />
        </div>
      </figure>
    </div>
  );
}

export default Ipad;
