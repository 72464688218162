import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import axios from "axios";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import { createNewBook, clearState } from "../redux/action/action";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectTemplate } from "../redux/action/action";
import { styled } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import CloseIcon from "@mui/icons-material/Close";
import HTMLFlipBook from "react-pageflip";
import AWS from "aws-sdk";
import {
  TEMPLATE_1_CONTENT,
  TEMPLATE_2_CONTENT,
  TEMPLATE_3_CONTENT,
  TEMPLATE_4_CONTENT,
} from "./TemplateContent";
const FormControlLabel1 = styled(FormControlLabel)({
  "& .MuiButtonBase-root": {
    padding: "0px",
    marginLeft: "10px",
    marginRight: "10px",
  },
});

const CssTextField = styled(TextField)({
  "& .MuiInputBase-root": {
    borderRadius: "12px",
    width: "100%",
    border: "0.2px solid #503981",
  },
  "&:hover .MuiInputBase-root": {
    border: "0.9px solid #503981",
  },
});
const PurpleRadio = styled(Radio)(({ theme }) => ({
  color: "#503981",
  "&.Mui-checked": {
    color: "#503981",
    backgroundColor: "#503981",
  },
}));

const Page = React.forwardRef((props, ref) => {
  return (
    <div className="demoPage" ref={ref}>
      {/* <h1>Page Header</h1> */}
      <p>{props.children}</p>
      {/* <p>Page number: {props.number}</p> */}
    </div>
  );
});

export default function Modal({ open, setModalOpen, heading, mode }) {
  const [selectedTemplate, setSelectedTemplate] = useState("ARCHITECT");
  const [templateContent, setTemplateContent] = useState({ __html: "" });
  const [formData, setFormData] = React.useState({
    title: "",
    subtitle: "",
    author: "",
    templateStyle: {},
  });
  const [loading, setLoading] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [selectedOption, setSelectedOption] = useState(
    "https://simplymemoirs.s3.us-west-1.amazonaws.com/images/download.jpg"
  );

  const dispatch = useDispatch();
  const book = useSelector((s) => s.createbookreducer.newBook?.response?._id);
  const navigate = useNavigate();
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleChangeRadio = (event) => {
    setSelectedTemplate(event.target.value);
  };
  useEffect(() => {
    handleMenuItemClick("ARCHITECT");
  }, []);
  useEffect(() => {
    if (book) {
      navigate(`/editor/${book}`);
      dispatch(clearState());
    }
  }, [book]);

  const handleMenuItemClick = (templateName) => {
    dispatch(selectTemplate(templateName));

    let content = "";
    let templateStyle = {};

    if (templateName === "ARCHITECT") {
      content = TEMPLATE_1_CONTENT;
      templateStyle = { fontFamily: "Poppins", fontSize: "15.5px" }; // Define CSS styles for ARCHITECT
    } else if (templateName === "GOOD VIBES") {
      content = TEMPLATE_2_CONTENT;
      templateStyle = { fontFamily: "Georgia, serif", fontSize: "18px" }; // Define CSS styles for GOOD VIBES
    } else if (templateName === "COMFORT") {
      content = TEMPLATE_3_CONTENT;
      templateStyle = {
        fontFamily: "Courier New, monospace",
        fontSize: "14px",
      }; // Define CSS styles for COMFORT
    } else if (templateName === "Template 4") {
      content = TEMPLATE_4_CONTENT;
      templateStyle = {
        fontFamily: "Times New Roman, serif",
        fontSize: "20px",
      }; // Define CSS styles for Template 4
    }

    setTemplateContent({ __html: content });
    setFormData({ ...formData, templateStyle });
  };

  const handelSubmit = () => {
    setLoading(true);
    const formDataWithImageUrl = {
      ...formData,
      imageUrl: selectedOption,
      file: uploadedFile,
    };
    dispatch(createNewBook(formDataWithImageUrl));
    setTimeout(() => {
      handleClose();
      setFormData("");
      setUploadedFile(null); // Clear uploaded file state after submission
      setLoading(false);
    }, 2000);
  };
  const handleClose = () => {
    setModalOpen(false);
  };
  AWS.config.update({
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
    region: "us-west-1",
  });

  const handleFileUpload = async (file) => {
    if (!file) return;

    setUploadedFile(file);
  };

  const ColorButton = styled(Button)(() => ({
    fontSize: "1vw",
    fontFamily: "Poppins",
    backgroundImage: "linear-gradient(99deg, #6A1AC3 28.42%, #1D0A32 126.77%)",
    color: "white",
    "&:hover": {
      backgroundImage:
        "linear-gradient(99deg, #6A1AC3 28.42%, #1D0A32 126.77%)",
    },
  }));
  return (
    <React.Fragment>
      <Dialog
        // fullWidth={fullWidth}
        // maxWidth={maxWidth}
        open={open}
        // sx={{display:"flex",flexDirection:"row"}}
        onClose={handleClose}
        id="1"
        classes={{ paper: "custom-dialog-paper" }}
        sx={{ margin: 0, maxHeight: "auto" }}
      >
        <DialogContent sx={{ width: "50%", padding: "30px 50px" }} id="2">
          <h4 id="3" style={{ textAlign: "center", fontFamily: "Poppins" }}>
            {heading}
          </h4>
          <Box
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              m: "auto",
              width: "80%",
            }}
            id="4"
            enctype="multipart/form-data"
          >
            <CssTextField
              placeholder="Enter title"
              margin="normal"
              value={formData.title}
              onChange={handleChange}
              name="title"
              inputProps={{
                style: { textAlign: "center", fontFamily: "Poppins" },
              }}
              id="5"
            />
            <CssTextField
              placeholder="Enter sub-title"
              margin="normal"
              value={formData.subtitle}
              onChange={handleChange}
              name="subtitle"
              inputProps={{
                style: { textAlign: "center", fontFamily: "Poppins" },
              }}
            />
            <CssTextField
              placeholder="Enter author"
              margin="normal"
              value={formData.author}
              onChange={handleChange}
              name="author"
              inputProps={{
                style: { textAlign: "center", fontFamily: "Poppins" },
              }}
            />
            <CssTextField
              select
              value={selectedOption}
              onChange={(e) => {
                setSelectedOption(e.target.value);
              }}
              variant="outlined"
              margin="normal"
              inputProps={{
                style: { textAlign: "center", fontFamily: "Poppins" },
              }}
            >
              <MenuItem value="https://simplymemoirs.s3.us-west-1.amazonaws.com/images/book.webp-24">
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <img
                    src="https://simplymemoirs.s3.us-west-1.amazonaws.com/images/book.webp-24"
                    width="60px"
                    height="70px"
                  />
                  <p style={{ alignSelf: "center" }}>Cover 1</p>
                </div>
              </MenuItem>
              <MenuItem value="https://simplymemoirs.s3.us-west-1.amazonaws.com/images/istockphoto-172371649-612x612.jpg">
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <img
                    src="https://simplymemoirs.s3.us-west-1.amazonaws.com/images/istockphoto-172371649-612x612.jpg"
                    width="60px"
                  />
                  <p style={{ alignSelf: "center" }}>Cover 2</p>
                </div>
              </MenuItem>
              <MenuItem value="https://simplymemoirs.s3.us-west-1.amazonaws.com/images/Capture.PNG">
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <img
                    src="https://simplymemoirs.s3.us-west-1.amazonaws.com/images/Capture.PNG"
                    width="60px"
                  />
                  <p style={{ alignSelf: "center" }}>Cover 3</p>
                </div>
              </MenuItem>
              <MenuItem value="https://simplymemoirs.s3.us-west-1.amazonaws.com/images/book.1.PNG">
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <img
                    src="https://simplymemoirs.s3.us-west-1.amazonaws.com/images/1.PNG"
                    width="60px"
                  />
                  <p style={{ alignSelf: "center" }}>Cover 4</p>
                </div>
              </MenuItem>
            </CssTextField>
            {/* {mode === "upload" && (
              <input
                type="file"
                name="pdfFile"
                id="file"
                onChange={(e) => {
                  handleFileUpload(e.target.files[0]);
                }}
                accept=".pdf,.doc,.docx,application/vnd.google-apps.document"
              />
            )} */}

            {mode === "upload" && (
              <input
                type="file"
                name="file"
                id="file"
                onChange={(e) => handleFileUpload(e.target.files[0])}
                accept=".pdf,.doc,.docx,application/vnd.google-apps.document"
              />
            )}
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                fontFamily: "Poppins",
                mt: 1,
              }}
            >
              <p>Templates</p>
            </Box>
            <RadioGroup
              aria-label="template"
              name="template"
              value={selectedTemplate}
              onChange={handleChangeRadio}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                marginTop: "8px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <FormControlLabel1
                  value="ARCHITECT"
                  control={<PurpleRadio />}
                  onClick={() => {
                    handleMenuItemClick("ARCHITECT");
                  }}
                  style={{ marginLeft: "16px" }}
                />
                <div
                  style={{
                    width: "48px",
                    height: "23px",
                    marginTop: 5,
                    borderRadius: "6px",
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    paddingTop: "2px",
                  }}
                >
                  <p style={{ fontSize: "10px", fontWeight: 700 }}>ARCHITECT</p>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <FormControlLabel1
                  value="GOOD VIBES"
                  control={<PurpleRadio />}
                  onClick={() => {
                    handleMenuItemClick("GOOD VIBES");
                  }}
                  style={{ marginLeft: "15px" }}
                />
                <div
                  style={{
                    width: "62px",
                    height: "23px",
                    marginTop: 5,
                    borderRadius: "6px",
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    paddingTop: "2px",
                  }}
                >
                  <p style={{ fontSize: "10px", fontWeight: 700 }}>
                    GOOD VIBES
                  </p>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <FormControlLabel1
                  value="COMFORT"
                  control={<PurpleRadio />}
                  onClick={() => {
                    handleMenuItemClick("COMFORT");
                  }}
                  style={{ marginLeft: "15px" }}
                />
                <div
                  style={{
                    width: "45px",
                    height: "23px",
                    marginTop: 5,
                    borderRadius: "6px",
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    paddingTop: "2px",
                  }}
                >
                  <p style={{ fontSize: "10px", fontWeight: 700 }}>COMFORT</p>
                </div>
              </div>
              {/* <FormControlLabel1
                value="Template 4"
                control={<PurpleRadio />}
                onClick={() => {handleMenuItemClick("Template 4");handleTemplateOpen();}}
              /> */}
            </RadioGroup>
            <Box
              sx={{ width: "100%", display: "flex", justifyContent: "center" }}
            >
              <ColorButton
                variant="contained"
                color="primary"
                sx={{
                  mt: 2,
                  width: "60%",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  borderRadius: "15px",
                }}
                // type="submit"
                disabled={loading}
                onClick={handelSubmit}
              >
                Create your book
              </ColorButton>
            </Box>
          </Box>
        </DialogContent>
        <Box
          sx={{
            width: "50%",
            padding: "25px 55px 25px 45px",
          }}
        >
          <Paper
            elevation={3}
            sx={{
              width: "100%",
              height: "100%",
              borderRadius: "20px",
              padding: "20px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              fontFamily: formData.templateStyle
                ? formData.templateStyle.fontFamily
                : "inherit",
              fontSize: formData.templateStyle
                ? formData.templateStyle.fontSize
                : "inherit",
              overflowY: "hidden",
              scrollbarWidth: "thin",
              "&::-webkit-scrollbar": {
                width: "5px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "transparent",
              },
            }}
          >
            <HTMLFlipBook
              width={400}
              height={400}
              renderOnlyPageLengthChange={1}
              maxShadowOpacity={[1]}
              flippingTime={2000}
              showCover={true}
              drawShadow={0}
              swipeDistance={"60px"}
            >
              <Page number="1">
                <img src="https://simplymemoirs.s3.us-west-1.amazonaws.com/images/bookCover.avif-91" />
              </Page>
              <Page number="2">
                {" "}
                <h4>PREVIEW 1</h4>
                {templateContent && (
                  <p
                    dangerouslySetInnerHTML={{ __html: templateContent.__html }}
                  />
                )}
              </Page>
              <Page number="3">
                {" "}
                <h4>PREVIEW 2</h4>
                {templateContent && (
                  <p
                    dangerouslySetInnerHTML={{ __html: templateContent.__html }}
                  />
                )}
              </Page>
              <Page number="4">
                {" "}
                <h4>PREVIEW 3</h4>
                {templateContent && (
                  <p
                    dangerouslySetInnerHTML={{ __html: templateContent.__html }}
                  />
                )}
              </Page>
            </HTMLFlipBook>
          </Paper>
        </Box>
        <DialogActions>
          <Button
            onClick={handleClose}
            style={{
              margin: "6px",
              position: "absolute",
              right: 4,
              top: 4,
              padding: "0px",
              backgroundColor: "#503981",
              borderRadius: "20px",
              minWidth: "20px",
            }}
          >
            <CloseIcon style={{ color: "white" }} />
          </Button>
        </DialogActions>
      </Dialog>
      <style>
        {`
          .MuiDialog-paper {
            border-radius: 20px;
            max-width:96vw;
            width:96vw;
            flex-direction:row;
          }
          .css-1t1j96h-MuiPaper-root-MuiDialog-paper{
            max-height:89% !important;
          }
          .css-6hp17o-MuiList-root-MuiMenu-list {
            max-height: 220px;
            overflow-y: auto;
            scrollbar-width: thin;
          }
        `}
      </style>
    </React.Fragment>
  );
}
