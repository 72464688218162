import {
  FETCH_BOOK_REQUEST,
  FETCH_BOOK_SUCCESS,
  FETCH_BOOK_FAILURE,
  FETCH_SINGLE_BOOK_REQUEST,
  FETCH_SINGLE_BOOK_SUCCESS,
  FETCH_SINGLE_BOOK_FAILURE,
  DELETE_BOOK_REQUEST,
  DELETE_BOOK_FAILURE,
  DELETE_BOOK_SUCCESS,
  DELETE_CHAPTER_REQUEST,
  DELETE_CHAPTER_FAILURE,
  DELETE_CHAPTER_SUCCESS,
  SAVE_CHAPTER_REQUEST,
  SAVE_CHAPTER_FAILURE,
  SAVE_CHAPTER_SUCCESS,
  SAVE_DEDICATION_REQUEST,
  SAVE_DEDICATION_SUCCESS,
  SAVE_DEDICATION_FAILURE,
  SAVE_INTRODUCTION_REQUEST,
  SAVE_INTRODUCTION_SUCCESS,
  SAVE_INTRODUCTION_FAILURE,
  SAVE_FOREWORD_REQUEST,
  SAVE_FOREWORD_SUCCESS,
  SAVE_FOREWORD_FAILURE,
  SAVE_PREFACE_REQUEST,
  SAVE_PREFACE_SUCCESS,
  SAVE_PREFACE_FAILURE,
  SAVE_SUBCHAPTER_REQUEST,
  SAVE_SUBCHAPTER_FAILURE,
  SAVE_SUBCHAPTER_SUCCESS,
  DELETE_SUBCHAPTER_REQUEST,
  DELETE_SUBCHAPTER_FAILURE,
  DELETE_SUBCHAPTER_SUCCESS,
} from "../actiontype/actiontype";

let initialState = {
  preview: false,
  books: {
    name: "Testing",
    subtitle: "Books 1",
    author: "Hyder",
    chapters: [
      {
        title: "Chapter 1",
        subtitle: "",
        text: `<p>Chapter 1</p> <br/> <h1>This is heading</h1>`,
      },
    ],
  },
  data: [],
  newBook: {},
  singleBook: {},
  error: "",
  loading: false,
  user: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "PREVIEW":
      state.preview = !state.preview;

      return { ...state };
    case "ADD_CHAPTER":
      let payload = state.books.chapters;
      payload.push(action.payload);
      return {
        ...state,
        books: { ...state.books, chapters: [...payload] },
      };
    case "CHANGE_TEXT_CHAPTER":
      let chapters = state.singleBook?.response?.chapters;

      chapters[action.payload.index].chapterbody = action.payload.text;
      return {
        ...state,
        singleBook: { ...state.singleBook, chapters: [...chapters] },
      };
    case "CHANGE_CHAPTER_TITLE":
      let chapterTitle = state.singleBook?.response?.chapters;

      chapterTitle[action.payload.index].chaptername = action.payload.title;
      return {
        ...state,
        singleBook: { ...state.singleBook, chapters: [...chapterTitle] },
      };
    case "CLEAR_STATE":
      return {
        ...state,
        newBook: {},
        user: {},
        error: "",
      };

    case "CHANGE_SUBCHAPTER_TITLE":
      let subChapterTitle = state.singleBook?.response?.chapters?.subchapters;
      subChapterTitle[action.payload.index].subchaptertitle =
        action.payload.title;
      return {
        ...state,
      };

    case FETCH_BOOK_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case FETCH_BOOK_SUCCESS:
      return { ...state, loading: false, data: action.payload, error: "" };

    case FETCH_BOOK_FAILURE:
      return {
        ...state,
        loading: false,
        data: [],
        error: action.payload,
      };

    case FETCH_SINGLE_BOOK_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_SINGLE_BOOK_SUCCESS:
      return {
        ...state,
        loading: false,
        singleBook: { response: action.payload },
        error: "",
      };
    case FETCH_SINGLE_BOOK_FAILURE:
      return {
        ...state,
        loading: false,
        singleBook: {},
        error: action.payload,
      };

    case DELETE_BOOK_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_BOOK_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_BOOK_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case DELETE_CHAPTER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_CHAPTER_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case DELETE_CHAPTER_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case SAVE_CHAPTER_REQUEST:
      return {
        ...state,
        loading: false,
      };
    case SAVE_CHAPTER_FAILURE:
      return { ...state, error: action.payload, loading: false };
    case SAVE_CHAPTER_SUCCESS:
      return {
        ...state,
        loading: false,
      };
      case SAVE_DEDICATION_REQUEST:
        return {
          ...state,
          loading: false,
        };
      case SAVE_DEDICATION_FAILURE:
        return { ...state, error: action.payload, loading: false };
      case SAVE_DEDICATION_SUCCESS:
        return {
          ...state,
          loading: false,
        };
        case SAVE_FOREWORD_REQUEST:
          return {
            ...state,
            loading: false,
          };
        case SAVE_FOREWORD_FAILURE:
          return { ...state, error: action.payload, loading: false };
        case SAVE_FOREWORD_SUCCESS:
          return {
            ...state,
            loading: false,
          };
          case SAVE_PREFACE_REQUEST:
            return {
              ...state,
              loading: false,
            };
          case SAVE_PREFACE_FAILURE:
            return { ...state, error: action.payload, loading: false };
          case SAVE_PREFACE_SUCCESS:
            return {
              ...state,
              loading: false,
            };
            case SAVE_INTRODUCTION_REQUEST:
              return {
                ...state,
                loading: false,
              };
            case SAVE_INTRODUCTION_FAILURE:
              return { ...state, error: action.payload, loading: false };
            case SAVE_INTRODUCTION_SUCCESS:
              return {
                ...state,
                loading: false,
              };

    case SAVE_SUBCHAPTER_REQUEST:
      return {
        ...state,
        loading: false,
      };
    case SAVE_SUBCHAPTER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SAVE_SUBCHAPTER_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case DELETE_SUBCHAPTER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case DELETE_SUBCHAPTER_SUCCESS:
      return {
        ...state,
        loading: false,
        singleBook: action.payload,
      };

    case DELETE_SUBCHAPTER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return { ...state };
  }
};

export default reducer;
