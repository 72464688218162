import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";


function ResponsePopup({ response, onClose }) {
    const selectedTemplate = useSelector(
        (state) => state.template.selectedTemplate
      );
      const getFontStyles = () => {
        switch (selectedTemplate) {
          case "Template 1":
            return { fontFamily: "Poppins", fontSize: "15.5px" };
          case "Template 2":
            return { fontFamily: "Georgia, serif", fontSize: "18px" };
          case "Template 3":
            return { fontFamily: "Courier New, monospace", fontSize: "14px" };
          case "Template 4":
            return { fontFamily: "Times New Roman, serif", fontSize: "20px" };
          default:
            return {};
        }
      };
      const a4SheetHeight = "297mm";
  return (
    <>
    <Dialog open={true} onClose={onClose} maxWidth="xs" fullWidth style={{...getFontStyles(),}}classes={{ paper: 'custom-dialog-paper' }}>
      <DialogTitle style={{textAlign:"center",...getFontStyles()}}><h4>Response</h4></DialogTitle>
      <DialogContent style={{ height: a4SheetHeight, overflow: "auto" }}>
        <div>{response}</div>
      </DialogContent>
      <DialogActions>
      <Button onClick={onClose} style={{ position: "absolute", right: 4, top: 4,padding:"0px",backgroundColor:"#503981",borderRadius:"20px",minWidth:"20px" }}>
            <CloseIcon style={{color:"white"}}/>
          </Button>
      </DialogActions>
    </Dialog>
    <style>
    {`
      .MuiDialog-paper {
        border-radius: 20px;
      }
    `}
  </style>
  </>
  );
}

export default ResponsePopup;
