import * as React from "react";
import Button from "@mui/material/Button";
import { actionAI } from "../api/api";
import { stripHtmlTags } from "../utils/helper";
import CircularProgress from "@mui/material/CircularProgress";

export default function LinesButton({ title, val, setResponseAI }) {
  const [lines, setLines] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const handleClick = async (event) => {
    if (lines === "" || lines === undefined) {
      return;
    } else {
      const suggest = stripHtmlTags(val);

      if (suggest === "") {
        setResponseAI("Please Write something in text edtior");
        return;
      } else {
        try {
          setLoading(true);
          const res = await actionAI(
            `Add a ${lines} lines of ${title}`,
            suggest
          );
          setResponseAI(res.data);
          setLoading(false);
        } catch (err) {
          window.alert("Something went wrong");
          setLoading(false);
        }
      }
    }
  };

  return (
    <div className="w-100 d-inline">
      <Button
        id="demo-customized-button"
        variant="outlined"
        disableFocusRipple
        disableRipple
        onClick={handleClick}
        sx={{ fontSize: 9, display: "inline", width: "100%",padding:"5px",height:"8vh",border:"2px solid #503981",color:"#503981",fontFamily:"poppins"}}
        disabled={loading}
      >
        Add &nbsp;{" "}
        <input
          style={{ width: "35px", border:"1px solid #503981", borderRadius:"6px", textAlign:"center" }}
          type="number"
          value={lines}
          onChange={(e) => setLines(e.target.value)}
          disabled={val === "" || val === undefined ? true : false}
        />{" "}
        &nbsp; lines of &nbsp;
        {title}
        {loading && (
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
              width: "100%",
              height: "100%",
              marginTop: "-40px",
              marginBottom: "21px",
              position: "relative",
              top: "23%",
            }}
          >
            <CircularProgress color="inherit" size={16} />
          </div>
        )}
      </Button>
    </div>
  );
}
