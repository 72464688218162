import React from "react";
import "./book.css";

const BookComponent = ({
  title,
  author,
  subtitle,
  imageUrl,
  bookFrontCover,
}) => {
  return (
    <div>
      <li>
        <figure className="book-theme">
          {/* Front */}
          <ul className="hardcover_front">
            <li>
              <img
                src={bookFrontCover ? bookFrontCover : imageUrl}
                alt=""
                width="100%"
                style={{ height: "100%" }}
              />
            </li>
            <li></li>
          </ul>
          {/* Pages */}
          <ul className="page">
            <li></li>
            <li>
              <div>
                <p>
                  <b>Title</b>
                </p>
                <p style={{ marginBottom: "12px" }}>{title}</p>
                <p>
                  <b>Subtitle</b>
                </p>
                <p style={{ marginBottom: "12px" }}>{subtitle}</p>
                <p>
                  <b>Author</b>
                </p>
                <p>{author}</p>
              </div>
            </li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
          {/* Back */}
          <ul
            className="hardcover_back"
            style={{ boxShadow: "3px 1px 4px rgba(192, 192, 192)" }}
          >
            <li style={{ boxShadow: "3px 1px 4px rgba(192, 192, 192)" }}></li>
            <li></li>
          </ul>
          <ul className="book_spine">
            <li></li>
            <li></li>
          </ul>
        </figure>
      </li>
    </div>
  );
};

export default BookComponent;
